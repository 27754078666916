import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Box,
  Typography,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add'; // Icon for "Create New"
import { useLazyQuery } from '@apollo/client';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import LovDataTable from '../../fwk/dataGrid/LovDataTable';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import './AutoCompleteTextField.scss';

const filter = createFilterOptions();

// Styled Popper to expand width
const StyledPopper = styled(Popper)(({ theme }) => ({
  width: '600px', // Adjust width as needed
  [theme.breakpoints.down('sm')]: {
    width: '90vw', // Responsive width for smaller screens
  },
}));

/**
 * AutoCompleteTextField Component
 * 
 * Props:
 * - label: string - Label for the text field.
 * - initialValue: string - Initial value of the text field.
 * - searchQuery: GraphQL query - The query used to fetch search results.
 * - searchField: string - The field used for searching.
 * - columns: array - Columns configuration for the data table and dropdown display.
 * - onSelect: function - Callback when an item is selected.
 * - searchResultsField: string - The field in the GraphQL response containing the results.
 * - combineColumns: array - Columns to combine for display.
 * - modalTitle: string - Title for the selection modal.
 * - disabled: boolean - Whether the input is disabled.
 * - variables: object - Additional variables for the GraphQL query.
 * - createComponent: React component - Component to render for creating a new item.
 * - createVariables: object - Variables to pass to the createComponent.
 */
function AutoCompleteTextField({
  label,
  initialValue = '',
  searchQuery,
  searchField,
  columns, // Used for rendering multiple columns in dropdown
  onSelect,
  searchResultsField,
  combineColumns = [],
  modalTitle,
  disabled,
  variables = {},
  createComponent: CreateComponent, // New prop for the create component
  createVariables = {}, // Additional variables for create
  required = false,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false); // State for create modal
  const [searchText, setSearchText] = useState(initialValue);
  const [searchResults, setSearchResults] = useState([]);
  const [value, setValue] = useState(null); // State for selected value

  const [triggerSearch, { loading, data, error }] = useLazyQuery(searchQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setSearchText(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!loading && data) {
      const rawResults = data[searchResultsField];
      const resultsArray = Array.isArray(rawResults) ? rawResults : [rawResults];
      const transformedData = resultsArray.map(result => {
        const newResult = { ...result };
        combineColumns.forEach(combine => {
          newResult[combine.label] = combine.columns.map(col => result[col]).join(' ');
        });
        return newResult;
      });
      setSearchResults(transformedData);
    }
  }, [loading, data, searchResultsField, combineColumns]);

  // Handle input changes and trigger search
  const handleInputChange = (event, newInputValue, reason) => { console.log('in event',newInputValue);
    setSearchText(newInputValue);
    const isCleared = newInputValue === '';


  if (isCleared) {
    // If user clears the input, reset the associated ID in formValues
    onSelect(null);
    setValue(null);
    return;
  }
    if (newInputValue) {
      triggerSearch({
        variables: {
          [searchField]: newInputValue, // Passing the search term
          ...variables                    // Passing additional variables
        }
      });
    } else {
      setSearchResults([]);
    }
  };

  // Handle selection of an option
  const handleResultSelect = (event, selectedRow) => {
    if (selectedRow) {
      if (selectedRow.isCreateOption) {
        // Open the create modal if "Create New" is selected
        handleCreateModalOpen();
      } else {
        // Ensure onSelect callback is triggered with the selected row
        onSelect(selectedRow);

        // Update the input field with the selected label
        const primaryField = columns[0]?.field || searchField;
        setSearchText(selectedRow[primaryField] || '');

        // Update the value state
        setValue(selectedRow);

        // Close any open modals
        handleModalClose();
      }
    } else {
      // Clear selection
      setValue(null);
    }
  };

  // Open the selection modal
  const handleModalOpen = () => {
    if (!disabled) {
      setOpenModal(true);
      // Trigger search on modal open
      triggerSearch({
        variables: {
          [searchField]: searchText,
          ...variables
        }
      });
    }
  };

  // Close the selection modal
  const handleModalClose = () => {
    setOpenModal(false);
    setSearchResults([]);
  };

  // Open the create modal
  const handleCreateModalOpen = () => {
    setOpenCreateModal(true);
  };

  // Close the create modal
  const handleCreateModalClose = () => {
    setOpenCreateModal(false);
  };

  // Handle successful creation of a new item
  const handleCreateSuccess = (newItem) => {
    // Add the new item to searchResults
    const updatedResults = [newItem, ...searchResults];
    setSearchResults(updatedResults);
    // Set the new item as selected
    onSelect(newItem);
    const primaryField = columns[0]?.field || searchField;
    setSearchText(newItem[primaryField]);
    setValue(newItem); // Set the selected value
    // Close the create modal
    handleCreateModalClose();
    // Optionally, close the selection modal
    handleModalClose();
  };

  // Custom filterOptions to always include "Create New" when applicable
  const customFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (CreateComponent && params.inputValue.trim() !== '') {
      const createOption = {
        isCreateOption: true,
        id: 'create-new',
        label: 'Create New',
      };
      // Prepend "Create New" option
      return [createOption, ...filtered];
    }

    return filtered;
  };

  return (
    <div className='autocomplete'>
      <Autocomplete
        freeSolo
        selectOnFocus
        clearOnBlur={false}
        handleHomeEndKeys
        options={searchResults}
        filterOptions={customFilterOptions}
        getOptionLabel={(option) => {
          if (option.isCreateOption) {
            return option.label;
          }
          // Use the primary field for the input value
          const primaryField = columns[0]?.field || searchField;
          return option[primaryField] || '';
        }}
        inputValue={searchText}
        onInputChange={handleInputChange}
        onChange={handleResultSelect}
        value={value}
        disabled={disabled}
        PopperComponent={StyledPopper} // Use the custom Popper
        renderOption={(props, option) => {
          if (option.isCreateOption) {
            return (
              <li
                {...props}
                key={option.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2" color="primary">
                  {option.label}
                </Typography>
                <AddIcon color="primary" />
              </li>
            );
          }

          // Regular option rendering with multiple columns
          return (
            <li
              {...props}
              key={option.id}
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                width: '100%', // Ensure the option takes full width
              }}
            >
              {columns.map((col) => (
                <Typography
                  key={col.field}
                  variant="body2"
                  style={{
                    whiteSpace: 'normal',      // Allow text to wrap
                    wordBreak: 'break-word',   // Break long words
                    flex: 1,                    // Allow flexible width
                  }}
                >
                  {option[col.field]}
                </Typography>
              ))}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            required={required}
            label={label}
            fullWidth
            disabled={disabled}
            sx={{ mb: 2 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  <Button onClick={handleModalOpen} style={{ minWidth: 'auto' }} disabled={disabled}>
                    <SearchIcon />
                  </Button>
                </>
              ),
            }}
          />
        )}
        // Ensure the Autocomplete occupies full width
        style={{ width: '100%' }}
      />

      {/* Selection Modal */}
      <Dialog open={openModal} onClose={handleModalClose} maxWidth="md" fullWidth>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent style={{ minHeight: '300px', maxHeight: '600px', overflowY: 'auto' }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">Error fetching data.</Typography>
          ) : searchResults.length > 0 ? (
            <LovDataTable
              rows={searchResults}
              columns={[
                ...columns.map((col) => ({
                  field: col.field, // Use field for data binding
                  headerName: col.label, // Use label for headers
                  width: col.width || 150,
                  // Allow cell content to wrap
                  flex: 1,
                  sortable: true,
                })),
                ...combineColumns.map((combine) => ({
                  field: combine.label,
                  headerName: combine.label.replace(/_/g, ' '),
                  width: combine.width || 200,
                  flex: 1,
                  sortable: false,
                })),
              ]}
              onSelect={(selectedRow) => handleResultSelect(null, selectedRow)} // Pass selected row to handleResultSelect
            />
          ) : (
            <Typography>No search results found.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Create New Item Modal */}
      <Dialog open={openCreateModal} onClose={handleCreateModalClose} maxWidth="md" fullWidth>
        <DialogTitle>Create New Item</DialogTitle>
        <DialogContent>
          {CreateComponent ? (
            <CreateComponent
              onSuccess={handleCreateSuccess}
              onCancel={handleCreateModalClose}
              {...createVariables}
            />
          ) : (
            <Typography>No create component provided.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateModalClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AutoCompleteTextField;

import { gql } from '@apollo/client';

const bpmPartnerRoleTypesFragments = {
    allPartnerRoleTypeFields: gql`
        fragment PartnerRoleTypeFields on PartnerRoleType {
            role_id
            role_name
            description
        }
    `
};

export default bpmPartnerRoleTypesFragments;

function filterUnwantedFields(objArr, unwantedFields = ['__typename']) {
    // Check if objArr is an array
    if (Array.isArray(objArr)) {
        // Process each object in the array
        return objArr.map((obj) => {
            // Filter unwanted fields from the object
            return Object.keys(obj).reduce((filteredObj, key) => {
                if (!unwantedFields.includes(key)) {
                    filteredObj[key] = obj[key];
                }
                return filteredObj;
            }, {});
        });
    } else if (typeof objArr === 'object' && objArr !== null) {
        // If objArr is an object, filter unwanted fields directly
        return Object.keys(objArr).reduce((filteredObj, key) => {
            if (!unwantedFields.includes(key)) {
                filteredObj[key] = objArr[key];
            }
            return filteredObj;
        }, {});
    } else {
        // Handle other types of input (e.g., scalar values, null, undefined)
        console.error('Expected an array or object but received:', objArr);
        return [];
    }
}

export default filterUnwantedFields;

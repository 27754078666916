import { loginRequest, loginSuccess, loginFailure, logout, setMenuData } from './amSlice';
import { userMutations } from '../Users/graphql/amUserMutations';
import { GET_USER_MENUS } from '../Authentication/graphql/amUserMenuQueries';
import { client } from '../../../graphql/client'; // Import your Apollo Client instance

// Thunk action for login
export const loginUser = (username, password) => async (dispatch, getState) => {
  dispatch(loginRequest());

  try {
    // Call the login mutation with variables using Apollo Client
    const { data } = await client.mutate({
      mutation: userMutations.LOGIN_MUTATION,
      variables: {
        input: {
          username,
          password,
        },
      },
    });

    const { user, token } = data.loginUser;

    // Save token and user information to local storage
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('isLoggedIn', true);

    // Dispatch success action with user and token
    dispatch(loginSuccess({ user, token }));

    // Fetch menu data after successful login
    const state = getState();
    const roleCodes = state.AccessManagement.user.roles.map(role => role.role_code);

    const menuMuiData = await client.query({
      query: GET_USER_MENUS,
      variables: { roleCodes: roleCodes },
      fetchPolicy: 'network-only',
    });
    // Save menu data in both Redux state and localStorage
    dispatch(setMenuData(menuMuiData.data.getUserMenus));
    localStorage.setItem('menuData', JSON.stringify(menuMuiData.data.getUserMenus));


  } catch (error) {
    // Handle authorization errors
    if (error.message === 'Unauthorized') {
      // Dispatch failure action with custom error message
      dispatch(loginFailure('Invalid credentials'));
    } else {
      // Dispatch failure action with error message from server
      dispatch(loginFailure(error.message));
    }
    throw error; // Optional: rethrow error to handle it in the component
  }
};

// Thunk action for logout
export const logoutUser = () => async (dispatch) => {
  try {
    // Perform any additional cleanup actions if needed

    // Remove token and user information from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.setItem('isLoggedIn', false);

    // Dispatch logout action
    dispatch(logout());
  } catch (error) {
    // Handle logout failure
    console.error('Logout failed:', error);
  }
};

export const updateUserContext = (payload) => ({
  type: 'UPDATE_USER_CONTEXT',
  payload,
});


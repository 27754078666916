import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, Observable } from '@apollo/client';
import { authLink, errorLink } from '../middleware/apolloInterceptor';
import { startLoading, stopLoading } from '../redux/slices/loadingSlice';
import { store } from '../redux/store';

const serverUrl = process.env.REACT_APP_SERVER_GRAPHQL_URL;
const httpLink = createHttpLink({ uri: serverUrl });

const loadingMiddleware = new ApolloLink((operation, forward) => {
  // Start loading before the request is forwarded.
  store.dispatch(startLoading());

  return new Observable((observer) => {
    const subscription = forward(operation).subscribe({
      next: (result) => {
        observer.next(result);
      },
      error: (error) => {
        // Stop loading even if there's an error.
        store.dispatch(stopLoading());
        observer.error(error);
      },
      complete: () => {
        // Stop loading on complete.
        store.dispatch(stopLoading());
        observer.complete();
      },
    });

    // Cleanup function: unsubscribe when the operation is cancelled.
    return () => {
      subscription.unsubscribe();
    };
  });
});

// Combine all Apollo Links (order matters)
const client = new ApolloClient({
  link: ApolloLink.from([loadingMiddleware, errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export { client };

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, TextField, Box, Typography } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import partnerQueries from '../graphql/bpmPartnerQueries';
import partnerMutations from '../graphql/bpmPartnerMutations';
import { showStatus } from '../../../../redux/slices/statusSlice';
import { useDispatch } from 'react-redux';
import ResponsiveTabs from '../../../../components/fwk/Tabs/TabComponent';
import DynamicDropdown from '../../../../components/fwk/dropDownList/DynamicDropDown';
import { valueSetValueQueries } from '../../../ApplicationManagement/ValueSets/graphql/appValueSetValueQueries';
import ActionButtonGroup from '../../../../components/fwk/ActionButtonGroup/ActionButtonGroup';
import ConfirmModal from '../../../../components/fwk/Modals/ConfirmModal';
import PartnerContactsTab from './bpmPartnerContactsTab';
import PartnerAddressesTab from './bpmPartnerAddressesTab';
import PartnerRolesTab from './bpmPartnerRolesTab';
import PartnerRelationshipsTab from './bpmPartnerRelationshipsTab';
import PartnerAccountsTab from './bpmPartnerAccountsTab';
import PageHeader from '../../../../components/fwk/PageHeader/PageHeader';
import validateForm from '../../../../components/fwk/Validations/validationFramework';
import filterUnwantedFields from '../../../../utils/filterUnwantedFields';

function PartnerView() {
    const { partner_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isEditing = Boolean(partner_id);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    const [formValues, setFormValues] = useState({
        partner_code: '',
        partner_name: '',
        status: 'Active',
        primary_contact: { contact_name: '', contact_role: '', email: '', phone_number: '', is_primary_contact: true },
        primary_address: { address_type: 'Primary', street_address: '', city: '', state: '', postal_code: '', country: '' },
        contacts: [], // ✅ Initialize as an empty array
        addresses: [],
        roles: [],
        relationships: [],
        accounts: []
    });

    const [tabData, setTabData] = useState({
        contacts: [],
        addresses: [],
        roles: [],
        relationships: [],
        accounts: []
    });

    const { loading, error, data } = useQuery(partnerQueries.GET_PARTNER_BY_ID, {
        variables: { partner_id: parseInt(partner_id, 10) },
        skip: !partner_id,
        fetchPolicy: 'network-only',
    });

    const [createPartner] = useMutation(partnerMutations.CREATE_PARTNER);
    const [updatePartner] = useMutation(partnerMutations.UPDATE_PARTNER);
    const [deletePartner] = useMutation(partnerMutations.DELETE_PARTNER);

    useEffect(() => {
        if (data?.getPartnerById) {
            const partner = data.getPartnerById;
            setFormValues({
                partner_code: partner.partner_code,
                partner_name: partner.partner_name,
                status: partner.status,
                primary_contact: partner.primary_contact || { is_primary_contact: true },
                primary_address: partner.addresses?.find(addr => addr.is_primary) || {},
            });

            setTabData({
                contacts: partner.contacts || [],
                addresses: partner.addresses || [],
                roles: partner.roles || [],
                relationships: partner.relationships || [],
                accounts: partner.accounts || [],
            });
        }
    }, [data]);

    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    // ✅ Update primary contact AND add to contacts tab
    const handlePrimaryContactChange = (field, value) => {
        setFormValues((prev) => {
            const updatedPrimaryContact = { ...prev.primary_contact, [field]: value };
    
            setTabData((prevTabData) => {
                let updatedContacts = [...(prevTabData.contacts || [])];
    
                // Ensure the primary contact is always in the list
                const existingPrimaryIndex = updatedContacts.findIndex(contact => contact.is_primary_contact);
    
                if (existingPrimaryIndex !== -1) {
                    // Update existing primary contact
                    updatedContacts[existingPrimaryIndex] = { ...updatedPrimaryContact, is_primary_contact: true };
                } else {
                    // Add new primary contact entry
                    updatedContacts = [...updatedContacts, { ...updatedPrimaryContact, is_primary_contact: true }];
                }
    
                return { ...prevTabData, contacts: updatedContacts };
            });
    
            return { ...prev, primary_contact: updatedPrimaryContact };
        });
    };           

    const handlePrimaryAddressChange = (field, value) => {
        setFormValues((prev) => ({
            ...prev,
            primary_address: { ...prev.primary_address, [field]: value }
        }));
    };

    const handleSubmit = async () => {
        const filteredFormValues = filterUnwantedFields(formValues, ['primary_contact', 'primary_address']);

        try {
            const input = { ...filteredFormValues, ...tabData };

            const response = isEditing
                ? await updatePartner({ variables: { partner_id: parseInt(partner_id, 10), input } })
                : await createPartner({ variables: { input } });

            if (response.data) {
                dispatch(showStatus({ message: 'Partner saved successfully', severity: 'success' }));
                navigate('/bpm/partners');
            }
            setOpenConfirmationModal(false);
        } catch (error) {
            setOpenConfirmationModal(false);
            dispatch(showStatus({ message: `Error saving partner: ${error.message}`, severity: 'error' }));
        }
    };

    const handleClose = () => navigate('/bpm/partners');

    const validationSchema = {
        partner_name: ['required'],
        status: ['required'],
    };

    const handleConfirm = () => {
        const errors = validateForm(formValues, validationSchema, dispatch);
        if (Object.keys(errors).length > 0) {
            return;
        }
        setOpenConfirmationModal(true);
    };

    const tabs = useMemo(() => [
        {
            title: 'Contacts',
            content: <PartnerContactsTab 
                        data={tabData.contacts} 
                        setData={(data) => setTabData(prev => ({ ...prev, contacts: data }))} 
                        primaryContact={formValues.primary_contact} // ✅ Pass Primary Contact
                    />,
            visible: true,
        },
        {
            title: 'Addresses',
            content: <PartnerAddressesTab data={tabData.addresses} setData={(data) => setTabData(prev => ({ ...prev, addresses: data }))} />,
            visible: true,
        },
        {
            title: 'Roles',
            content: <PartnerRolesTab data={tabData.roles} setData={(data) => setTabData(prev => ({ ...prev, roles: data }))} />,
            visible: true,
        },
        {
            title: 'Relationships',
            content: <PartnerRelationshipsTab data={tabData.relationships} setData={(data) => setTabData(prev => ({ ...prev, relationships: data }))} />,
            visible: true,
        },
        {
            title: 'Accounts',
            content: <PartnerAccountsTab data={tabData.accounts} setData={(data) => setTabData(prev => ({ ...prev, accounts: data }))} />,
            visible: true,
        },
    ], [tabData]);

    const buttonsConfig = {
        save: { label: 'Save' },
        cancel: { label: 'Cancel' },
    };

    return (
        <Container>
            <PageHeader
                title={<Typography variant="h5">{isEditing ? 'Edit Partner' : 'Create Partner'}</Typography>}
                buttonsConfig={buttonsConfig}
                onSave={handleConfirm}
                onCancel={handleClose}
                showStatus={true}
            />
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        variant="standard"
                        name="partner_name"
                        label="Partner Name"
                        value={formValues.partner_name}
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        variant="standard"
                        name="partner_code"
                        label="Partner Code"
                        value={formValues.partner_code}
                        onChange={handleChange}
                        fullWidth
                    />
                    <DynamicDropdown
                        required
                        variant="standard"
                        query={valueSetValueQueries.GET_VALUE_SET_VALUE_BY_CODE}
                        variables={{ valueset_code: 'PARTNER_STATUS' }}
                        labelKey="value"
                        valueKey="value"
                        label="Status"
                        name="status"
                        value={formValues.status}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="standard"
                        name="primary_contact_name"
                        label="Primary Contact Name"
                        value={formValues.primary_contact.contact_name}
                        onChange={(e) => handlePrimaryContactChange('contact_name', e.target.value)}
                        fullWidth
                    />
                    <TextField
                        variant="standard"
                        name="primary_contact_role_name"
                        label="Primary Contact Role"
                        value={formValues.primary_contact.contact_role}
                        onChange={(e) => handlePrimaryContactChange('contact_role', e.target.value)}
                        fullWidth
                    />
                    <TextField
                        variant="standard"
                        name="primary_contact_email"
                        label="Primary Email"
                        value={formValues.primary_contact.email}
                        onChange={(e) => handlePrimaryContactChange('email', e.target.value)}
                        fullWidth
                    />
                    <TextField
                        variant="standard"
                        name="primary_contact_phone_number"
                        label="Primary Phone"
                        value={formValues.primary_contact.phone_number}
                        onChange={(e) => handlePrimaryContactChange('phone_number', e.target.value)}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <ResponsiveTabs tabs={tabs} />
            <ConfirmModal open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)} onConfirm={handleSubmit} message="Do you want to save the changes?" />
        </Container>
    );
}

export default PartnerView;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    TextField,
    Typography,
    Box,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import publisherQueries from '../graphql/pbmPublisherQueries';
import publisherMutations from '../graphql/pbmPublisherMutations';
import { showStatus } from '../../../../redux/slices/statusSlice';
import { useDispatch } from 'react-redux';
import ActionButtonGroup from '../../../../components/fwk/ActionButtonGroup/ActionButtonGroup';

function PublisherView() {
    const { publisher_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        publisher_name: '',
        country: '',
        established_year: '',
        website_url: '',
        address: '',
        city: '',
        state: '',
        postal_code: '',
        phone_numbers: [],
        emails: [],
    });

    const { data, loading, error } = useQuery(publisherQueries.GET_PUBLISHER_BY_ID, {
        variables: { publisher_id: parseInt(publisher_id, 10) },
        skip: !publisher_id,
    });

    const [createOrUpdatePublisher] = useMutation(publisherMutations.CREATE_OR_UPDATE_PUBLISHER);

    useEffect(() => {
        if (data && data.getPublisherById) {
            setFormValues(data.getPublisherById);
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        try {
            const input = {
                ...formValues,
                phone_numbers: formValues.phone_numbers.map((num) => num.trim()).filter(Boolean), // Clean up phone numbers
                emails: formValues.emails.map((email) => email.trim()).filter(Boolean), // Clean up emails
            };
            await createOrUpdatePublisher({ variables: { input } });
            dispatch(showStatus({ message: 'Publisher saved successfully', severity: 'success' }));
            navigate('/pbm/publisher');
        } catch (err) {
            dispatch(showStatus({ message: `Error saving publisher: ${err.message}`, severity: 'error' }));
        }
    };

    const handleClose = () => navigate('/pbm/publisher');

    const buttonsConfig = {
        save: { label: 'Save'},
        cancel: { label: 'Cancel' },
    };

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error: {error.message}</Typography>;

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">{publisher_id ? 'Edit Publisher' : 'Create Publisher'}</Typography>
                <Box>
                    <ActionButtonGroup
                        buttonsConfig={buttonsConfig}
                        onSave={() => handleSubmit()}
                        onCancel={handleClose}
                    />
                </Box>
            </Box>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Publisher Name"
                            name="publisher_name"
                            value={formValues.publisher_name}
                            onChange={handleChange}
                            fullWidth
                            required
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Country"
                            name="country"
                            value={formValues.country}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Established Year"
                            name="established_year"
                            value={formValues.established_year}
                            onChange={handleChange}
                            fullWidth
                            type="number"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Website URL"
                            name="website_url"
                            value={formValues.website_url}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Address"
                            name="address"
                            value={formValues.address}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="City"
                            name="city"
                            value={formValues.city}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="State"
                            name="state"
                            value={formValues.state}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Postal Code"
                            name="postal_code"
                            value={formValues.postal_code}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Phone Numbers (comma-separated)"
                            name="phone_numbers"
                            value={formValues.phone_numbers.join(', ')}
                            onChange={(e) =>
                                setFormValues((prev) => ({
                                    ...prev,
                                    phone_numbers: e.target.value.split(',').map((num) => num.trim()),
                                }))
                            }
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Emails (comma-separated)"
                            name="emails"
                            value={formValues.emails.join(', ')}
                            onChange={(e) =>
                                setFormValues((prev) => ({
                                    ...prev,
                                    emails: e.target.value.split(',').map((email) => email.trim()),
                                }))
                            }
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default PublisherView;

import { gql } from '@apollo/client';
import bpmPartnerRoleTypesFragments from './bpmPartnerRoleTypesFragments';

const bpmPartnerRoleTypesQueries = {
    GET_ALL_PARTNER_ROLE_TYPES: gql`
        query getAllPartnerRoleTypes {
            getAllPartnerRoleTypes {
                ...PartnerRoleTypeFields
            }
        }
        ${bpmPartnerRoleTypesFragments.allPartnerRoleTypeFields}
    `,

    GET_PARTNER_ROLE_TYPE_BY_ID: gql`
        query getPartnerRoleTypeById($role_id: Int!) {
            getPartnerRoleTypeById(role_id: $role_id) {
                ...PartnerRoleTypeFields
            }
        }
        ${bpmPartnerRoleTypesFragments.allPartnerRoleTypeFields}
    `,

    GET_PARTNER_ROLE_TYPE_BY_NAME: gql`
        query getPartnerRoleTypeByName($role_name: String!) {
            getPartnerRoleTypeByName(role_name: $role_name) {
                ...PartnerRoleTypeFields
            }
        }
        ${bpmPartnerRoleTypesFragments.allPartnerRoleTypeFields}
    `
};

export default bpmPartnerRoleTypesQueries;

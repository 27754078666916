import { gql } from '@apollo/client';
import bpmPartnerRoleTypesFragments from './bpmPartnerRoleTypesFragments';

const bpmPartnerRoleTypesMutations = {
    CREATE_PARTNER_ROLE_TYPE: gql`
        mutation createPartnerRoleType($input: CreatePartnerRoleTypeInput!) {
            createPartnerRoleType(input: $input) {
                ...PartnerRoleTypeFields
            }
        }
        ${bpmPartnerRoleTypesFragments.allPartnerRoleTypeFields}
    `,

    UPDATE_PARTNER_ROLE_TYPE: gql`
        mutation updatePartnerRoleType($input: UpdatePartnerRoleTypeInput!) {
            updatePartnerRoleType(input: $input) {
                ...PartnerRoleTypeFields
            }
        }
        ${bpmPartnerRoleTypesFragments.allPartnerRoleTypeFields}
    `,

    DELETE_PARTNER_ROLE_TYPE: gql`
        mutation deletePartnerRoleType($role_id: Int!) {
            deletePartnerRoleType(role_id: $role_id)
        }
    `
};

export default bpmPartnerRoleTypesMutations;

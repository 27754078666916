import { gql } from '@apollo/client';

const publisherFragments = {
    allPublisherFields: gql`
        fragment PublisherFields on Publisher {
            publisher_id
            publisher_name
            country
            established_year
            website_url
            address
            city
            state
            postal_code
            phone_numbers
            emails
        }
    `,
};

export default publisherFragments;

import { gql } from '@apollo/client';
import publisherFragments from './pbmPublisherFragments';

const publisherMutations = {
    CREATE_OR_UPDATE_PUBLISHER: gql`
        mutation createOrUpdatePublisher($input: PublisherInput!) {
            createOrUpdatePublisher(input: $input) {
                ...PublisherFields
            }
        }
        ${publisherFragments.allPublisherFields}
    `,
    DELETE_PUBLISHER: gql`
        mutation deletePublisher($publisher_id: Int!) {
            deletePublisher(publisher_id: $publisher_id)
        }
    `,
};

export default publisherMutations;

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button
} from '@mui/material';
import { useDispatch } from 'react-redux';
import partnerQueries from '../graphql/bpmPartnerQueries';
import partnerMutations from '../graphql/bpmPartnerMutations';
import ListDataTable from '../../../../components/fwk/dataGrid/ListDataTable';
import FloatingSearch from '../../../../components/fwk/FloatingSearch/FloatingSearch';
import { showStatus } from '../../../../redux/slices/statusSlice';
import ActionButtonGroup from '../../../../components/fwk/ActionButtonGroup/ActionButtonGroup';
import debounce from 'lodash.debounce';
import PageHeader from '../../../../components/fwk/PageHeader/PageHeader';

function PartnerList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State for pagination, sorting, and search filters
    const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
    const [sortModel, setSortModel] = useState([{ field: 'partner_name', sort: 'asc' }]);
    const [searchFilters, setSearchFilters] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const queryVariables = useMemo(() => ({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
        filters: searchFilters,
        orderBy: sortModel[0]?.field || 'partner_name',
        orderDirection: sortModel[0]?.sort?.toUpperCase() || 'ASC',
    }), [pagination, searchFilters, sortModel]);

    const { loading, error, data, refetch } = useQuery(partnerQueries.GET_ALL_PARTNERS, {
        variables: queryVariables,
        fetchPolicy: 'network-only',
    });

    const [deletePartner] = useMutation(partnerMutations.DELETE_PARTNER);

    // Debounced search handler
    const debouncedSearch = useMemo(() =>
        debounce((filters) => {
            setSearchFilters(filters);
            setPagination((prev) => ({ ...prev, page: 0 }));
        }, 300), []);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const handleSearch = useCallback((filters) => {
        debouncedSearch(filters);
    }, [debouncedSearch]);

    const handlePaginationChange = useCallback((model) => {
        setPagination({
            page: model.page,
            pageSize: model.pageSize,
        });
    }, []);

    const handleSortChange = useCallback((newSortModel) => {
        setSortModel(newSortModel);
    }, []);

    const handleRowSelection = useCallback((selectionModel) => {
        setSelectedRows(selectionModel);
    }, []);

    const handleRowClick = useCallback((params) => {
        navigate(`/bpm/partnerview/${params.row.partner_id}`);
    }, [navigate]);

    const handleDelete = async () => {
        try {
            await Promise.all(selectedRows.map((partnerId) =>
                deletePartner({ variables: { partner_id: partnerId } })
            ));
            setSelectedRows([]);
            setOpenDialog(false);
            dispatch(showStatus({ message: 'Partner(s) deleted successfully', severity: 'success' }));
            refetch();
        } catch (err) {
            dispatch(showStatus({ message: `Error deleting partner(s): ${err.message}`, severity: 'error' }));
        }
    };

    const handleAddClick = () => navigate('/bpm/partnerview/create');

    // Transform data for table
    const rows = data?.getAllPartners?.partners?.map((partner) => ({
        partner_id: partner?.partner_id || '',
        partner_code: partner?.partner_code || '',
        partner_name: partner?.partner_name || '',
        status: partner?.status || '',
    })) || [];

    const totalCount = data?.getAllPartners?.totalCount || 0;

    const columns = [
        { field: 'partner_code', headerName: 'Partner Code', width: 150, operators: ['Like', '='] },
        { field: 'partner_name', headerName: 'Partner Name', width: 250, operators: ['Like', '='] },
        { field: 'status', headerName: 'Status', width: 120, operators: ['=', '!='] },
    ];

    const tableConfig = {
        rows,
        columns,
        uniqueIdColumn: 'partner_id',
        rowCount: totalCount,
        paginationModel: pagination,
        paginationMode: 'server',
        sortingMode: 'server',
        onPaginationModelChange: handlePaginationChange,
        onSortModelChange: handleSortChange,
        style: { cursor: 'pointer' },
        onRowClick: handleRowClick,
        onSelectionModelChange: handleRowSelection,
    };

    const buttonsConfig = {
        add: { label: 'Add' },
        delete: { label: 'Delete', disabled: selectedRows.length === 0 },
    };

    return (
        <Box padding={2}>
            <PageHeader
                title="Partners"
                buttonsConfig={buttonsConfig}
                onAdd={handleAddClick}
                onDelete={() => setOpenDialog(true)}
                showStatus={true}
            />
            {error && <Typography color="error">Error: {error.message}</Typography>}

            <FloatingSearch columns={columns} onSearch={handleSearch} />

            <Box position="relative">
                {loading && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                        bgcolor="rgba(255, 255, 255, 0.7)"
                        zIndex={999}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <ListDataTable config={tableConfig} />
            </Box>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>Are you sure you want to delete the selected partner(s)?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleDelete} color="secondary">Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default PartnerList;

import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import publisherQueries from '../graphql/pbmPublisherQueries';
import publisherMutations from '../graphql/pbmPublisherMutations';
import ListDataTable from '../../../../components/fwk/dataGrid/ListDataTable';
import FloatingSearch from '../../../../components/fwk/FloatingSearch/FloatingSearch';
import { showStatus } from '../../../../redux/slices/statusSlice';
import ActionButtonGroup from '../../../../components/fwk/ActionButtonGroup/ActionButtonGroup';
import debounce from 'lodash.debounce';

function PublisherList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // States for pagination, sorting, and search
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [sortModel, setSortModel] = useState([{ field: 'publisher_id', sort: 'asc' }]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const queryVariables = useMemo(() => ({
    page: pagination.page + 1,
    pageSize: pagination.pageSize,
    filters: searchFilters,
    orderBy: sortModel[0]?.field || 'publisher_id',
    orderDirection: sortModel[0]?.sort?.toUpperCase() || 'ASC',
  }), [pagination, searchFilters, sortModel]);

  const { loading, error, data, refetch } = useQuery(publisherQueries.GET_ALL_PUBLISHERS, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  });

  const [deletePublisher] = useMutation(publisherMutations.DELETE_PUBLISHER);

  // Debounced search handler
  const debouncedSearch = useMemo(() =>
    debounce((filters) => {
      setSearchFilters(filters);
      setPagination((prev) => ({ ...prev, page: 0 })); // Reset to the first page
    }, 300), []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel(); // Cleanup on unmount
    };
  }, [debouncedSearch]);

  const handleSearch = useCallback((filters) => {
    debouncedSearch(filters);
  }, [debouncedSearch]);

  const handlePaginationModelChange = useCallback((model) => {
    setPagination({
      page: model.page,
      pageSize: model.pageSize,
    });
  }, []);

  const handleSortChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const handleRowSelection = useCallback((selectionModel) => {
    setSelectedRows(selectionModel);
  }, []);

  const handleRowClick = useCallback((params) => {
    navigate(`/pbm/publisher/${params.row.publisher_id}`);
  }, [navigate]);

  const handleDelete = async () => {
    try {
      await Promise.all(selectedRows.map((publisherId) =>
        deletePublisher({ variables: { publisher_id: publisherId } })
      ));
      setSelectedRows([]);
      setOpenDialog(false);
      dispatch(showStatus({ message: 'Publisher(s) deleted successfully', severity: 'success' }));
      refetch();
    } catch (err) {
      dispatch(showStatus({ message: `Error deleting publisher(s): ${err.message}`, severity: 'error' }));
    }
  };

  const handleAddClick = () => navigate('/pbm/publisher/create');

  const rows = data?.getAllPublishers?.publishers?.map((publisher) => ({
    publisher_id: publisher.publisher_id,
    publisher_name: publisher.publisher_name,
    country: publisher.country || '',
    established_year: publisher.established_year || '',
  })) || [];

  const totalCount = data?.getAllPublishers?.totalCount || 0;

  const columns = [
    { field: 'publisher_name', headerName: 'Publisher Name', width: 200, operators: ['=', 'Like'] },
    { field: 'country', headerName: 'Country', width: 200, operators: ['=', 'Like'] },
    { field: 'established_year', headerName: 'Established Year', width: 150, operators: ['=', '<', '>'] },
  ];

  const tableConfig = {
    rows,
    columns,
    uniqueIdColumn: 'publisher_id',
    rowCount: totalCount,
    paginationModel: pagination,
    paginationMode: 'server',
    sortingMode: 'server',
    onPaginationModelChange: handlePaginationModelChange,
    onSortModelChange: handleSortChange,
    style: { cursor: 'pointer' },
    onRowClick: handleRowClick,
    onSelectionModelChange: handleRowSelection,
  };

  const buttonsConfig = {
    add: { label: 'Add' },
    delete: { label: 'Delete', disabled: selectedRows.length === 0 },
  };

  return (
    <Box padding={2}>
      <Grid container justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Grid item>
          <h3>Publishers</h3>
        </Grid>
        <Grid item>
          <ActionButtonGroup
            buttonsConfig={buttonsConfig}
            onAdd={handleAddClick}
            onDelete={() => setOpenDialog(true)}
          />
        </Grid>
      </Grid>

      {error && (
        <Typography color="error" style={{ marginBottom: '16px' }}>
          Error: {error.message}
        </Typography>
      )}

      <FloatingSearch columns={columns} onSearch={handleSearch} />

      <Box position="relative">
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            bgcolor="rgba(255, 255, 255, 0.7)"
            zIndex={999}
          >
            <CircularProgress />
          </Box>
        )}
        <ListDataTable config={tableConfig} />
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the selected publisher(s)?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default PublisherList;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filters: {}, // Stores search filters
    lastPage: "", // Stores the last visited page with floating search
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload.filters;
            state.lastPage = action.payload.page; // Store the page name
        },
        clearFilters: (state) => {
            state.filters = {};
            state.lastPage = "";
        },
    },
});

export const { setFilters, clearFilters } = searchSlice.actions;
export default searchSlice.reducer;

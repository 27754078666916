import moment from 'moment';
import { setFilters } from '../redux/slices/searchSlice';

export const applyFilters = (searchFields, columns, pageName, dispatch) => { console.log('searchFields',searchFields);
  // Map the searchFields object to an array of filters.
  const filters = Object.entries(searchFields)
    .map(([field, { operator, value, value2 }]) => {
      const column = columns.find((col) => col.field === field);
      if (!column) return null;
      return {
        field,
        fetchFrom: column.fetchFrom || '',
        operator: operator || '=',
        value:
          column.colType === 'date' && value
            ? moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : value,
        value2:
          column.colType === 'date' && value2
            ? moment(value2, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : value2,
      };
    })
    .filter((filter) => filter !== null);

  // Update the Redux store with the new filters.
  dispatch(setFilters({ filters, page: pageName }));
  return filters;
};

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import EditableTableV2 from '../../../../components/fwk/dataGrid/EditableTableV2';

const PartnerContactsTab = ({ data, setData, primaryContact }) => {
    const tableRef = useRef(null);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        let updatedRows = Array.isArray(data) ? [...data] : [];

        // Ensure the primary contact is always in the list
        if (primaryContact?.contact_name) {
            const existingPrimaryIndex = updatedRows.findIndex(row => row.is_primary_contact);

            if (existingPrimaryIndex !== -1) {
                updatedRows[existingPrimaryIndex] = { ...primaryContact, is_primary_contact: true };
            } else {
                updatedRows = [...updatedRows, { ...primaryContact, is_primary_contact: true }];
            }
        }

        setRows(updatedRows);
    }, [data, primaryContact]);

    useEffect(() => {
        setData(rows);
    }, [rows, setData]);

    const handleRowsChange = (updatedRows) => {
        setRows(updatedRows);
        setData(updatedRows);
    };

    const handleDeleteRows = (selectedRowIds) => {
        setRows(rows.filter(row => !selectedRowIds.includes(row.id)));
        setData(rows.filter(row => !selectedRowIds.includes(row.id)));
    };

    const handleCheckboxChange = (id) => {
        setRows((prevRows) => {
            return prevRows.map(row => {
                if (row.id === id) {
                    return { ...row, is_primary_contact: true }; // ✅ Always checked
                } else {
                    return { ...row, is_primary_contact: false }; // ✅ Uncheck others
                }
            });
        });
    };

    const columns = useMemo(() => [
        { field: 'contact_name', label: 'Contact Name', width: 200, inputType: 'text' },
        { field: 'contact_role', label: 'Role', width: 150, inputType: 'text' },
        { field: 'email', label: 'Email', width: 200, inputType: 'text' },
        { field: 'phone_number', label: 'Phone', width: 150, inputType: 'text' },
        {
            field: 'is_primary_contact',
            label: 'Primary Contact',
            width: 100,
            inputType: 'checkbox',
            onChange: handleCheckboxChange,
            disabled: true, // ✅ Primary Contact should always be checked and uneditable
        },
    ], []);

    return (
        <Box>
            <EditableTableV2
                ref={tableRef}
                rows={rows}
                columns={columns}
                onRowsChange={handleRowsChange}
                onDeleteRows={handleDeleteRows}
            />
        </Box>
    );
};

export default PartnerContactsTab;

import { gql } from '@apollo/client';
import partnerFragments from './bpmPartnerFragments';

const partnerMutations = {
    CREATE_PARTNER: gql`
        mutation createPartner($input: PartnerInput!) {
            createPartner(input: $input) {
                ...PartnerFields
            }
        }
        ${partnerFragments.allPartnerFields}
    `,

    UPDATE_PARTNER: gql`
        mutation updatePartner($partner_id: Int!, $input: PartnerInput!) {
            updatePartner(partner_id: $partner_id, input: $input) {
                ...PartnerFields
            }
        }
        ${partnerFragments.allPartnerFields}
    `,

    DELETE_PARTNER: gql`
        mutation deletePartner($partner_id: Int!) {
            deletePartner(partner_id: $partner_id)
        }
    `
};

export default partnerMutations;

import { gql } from '@apollo/client';

const UPLOAD_DOCUMENT_MUTATION = gql`
  mutation UploadDocument($filePath: String!) {
    uploadDocument(filePath: $filePath) {
      success
      message
      filePath
    }
  }
`;

const DOWNLOAD_DOCUMENT_QUERY = gql`
  query DownloadDocument($filePath: String!) {
    downloadDocument(filePath: $filePath) {
      success
      message
      fileContent
    }
  }
`;

export const uploadFile = async (file, uploadDocument=UPLOAD_DOCUMENT_MUTATION, dispatch, showStatus) => {
  if (!file) {
    dispatch(showStatus({ message: 'No file selected', severity: 'error' }));
    return null;
  }

  try {
    // Retrieve token from local storage or other storage method
    const token = localStorage.getItem('token'); 

    // Step 1: Upload the file to the Express server
    const formData = new FormData();
    formData.append('file', file);

    const uploadResponse = await fetch('http://localhost:3600/file/upload', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const uploadData = await uploadResponse.json();

    if (uploadData.success) {
      // Step 2: Use the returned filePath to call the GraphQL mutation
      const response = await uploadDocument({ variables: { filePath: uploadData.filePath } });
      const { data } = response;

      if (data.uploadDocument.success) {
        dispatch(showStatus({ message: 'Document uploaded successfully', severity: 'success' }));
        return data.uploadDocument.filePath;
      } else {
        dispatch(showStatus({ message: data.uploadDocument.message, severity: 'error' }));
        return null;
      }
    } else {
      dispatch(showStatus({ message: uploadData.message, severity: 'error' }));
      return null;
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    dispatch(showStatus({ message: `Error uploading document: ${error.message}`, severity: 'error' }));
    return null;
  }
};

export const downloadFile = async (filePath, downloadDocument=DOWNLOAD_DOCUMENT_QUERY, dispatch, showStatus) => {
  if (!filePath) {
    dispatch(showStatus({ message: 'No file path provided', severity: 'error' }));
    return;
  }

  try {
    // Retrieve token from local storage or other storage method
    const token = localStorage.getItem('token');

    const response = await downloadDocument({ variables: { filePath } });
    const { data } = response;

    if (data.downloadDocument.success) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(new Blob([data.downloadDocument.fileContent], { type: 'application/octet-stream' }));
      link.download = filePath.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(showStatus({ message: 'Document downloaded successfully', severity: 'success' }));
    } else {
      dispatch(showStatus({ message: data.downloadDocument.message, severity: 'error' }));
    }
  } catch (error) {
    console.error('Error downloading file:', error);
    dispatch(showStatus({ message: `Error downloading document: ${error.message}`, severity: 'error' }));
  }
};

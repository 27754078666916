import { gql } from '@apollo/client';

const authorFragments = {
    allAuthorFields: gql`
        fragment AuthorFields on Author {
            author_id
            author_name
            biography
            date_of_birth
            nationality
            address
            city
            state
            postal_code
            country
            phone_numbers
            emails
        }
    `,
};

export default authorFragments;

import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import ActionButtonGroup from '../ActionButtonGroup/ActionButtonGroup'; // Adjust the path as needed

const PageHeader = ({
    title,
    statusMessage,
    statusStyle = { color: 'darkgreen', fontWeight: 'bold' },
    buttonsConfig,
    onSave,
    onCancel,
    onAdd,
    onDelete,
    actionsConfig,
    showStatus = false,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems={isSmallScreen ? 'flex-start' : 'center'}
            mb={2}
            gap={isSmallScreen ? 2 : 0} // Adjust spacing for small screens
        >
            <Typography variant="h6" component="h3">
                {title}
            </Typography>
            {showStatus && statusMessage && (
                <Typography
                    align={'left'}
                    style={statusStyle}
                >
                    {statusMessage}
                </Typography>
            )}
            <Box>
                <ActionButtonGroup
                    buttonsConfig={buttonsConfig}
                    onSave={onSave}
                    onCancel={onCancel}
                    onAdd={onAdd}
                    onDelete={onDelete}
                    actionsConfig={actionsConfig}
                />
            </Box>
        </Box>
    );
};

export default PageHeader;

import moment from "moment";

export const validationTypes = {
    required: (value) => (value && value.toString().trim() !== '' ? null : 'is required.'),
    number: (value) => (!isNaN(value) ? null : 'must be a number.'),
    mobile: (value) => (/^\d{10}$/.test(value) ? null : 'Mobile number must be 10 digits.'),
    email: (value) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'Invalid email format.'),
    
    minLength: (length) => (value) => 
        (value.length >= length ? null : `Must be at least ${length} characters long.`),
    
    maxLength: (length) => (value) => 
        (value.length <= length ? null : `Must be less than ${length} characters long.`),
    
    positive: (value) => (parseFloat(value) > 0 ? null : 'Value must be greater than zero.'),
    
    integer: (value) => (Number.isInteger(Number(value)) ? null : 'Must be an integer.'),

    date: (value) => (
        moment(value, 'DD-MM-YYYY', true).isValid() ? null : 'Invalid date format (expected DD-MM-YYYY).'
    ),

    pastDate: (value) => (
        moment(value, 'DD-MM-YYYY', true).isValid() && moment(value, 'DD-MM-YYYY').isBefore(moment()) 
            ? null 
            : 'Date must be in the past.'
    ),

    futureDate: (value) => (
        moment(value, 'DD-MM-YYYY', true).isValid() && moment(value, 'DD-MM-YYYY').isAfter(moment()) 
            ? null 
            : 'Date must be in the future.'
    ),

    withinRange: (min, max) => (value) => (
        (value >= min && value <= max) ? null : `Value must be between ${min} and ${max}.`
    ),

    regex: (pattern, errorMessage) => (value) => (
        pattern.test(value) ? null : errorMessage || 'Invalid format.'
    ),
};

import { combineReducers } from 'redux';
import notificationsReducer from './slices/notificationsSlice';
import menuReducer from '../redux/slices/menuSlice';
import amReducer from '../modules/AccessManagement/redux';
import statusSlice from './slices/statusSlice';
import modalSlice from './slices/modalSlice'
import formBuilderReducer from '../modules/CodeGenerator/Naksha/redux/slices/formBuilderSlice';
import loadingReducer from './slices/loadingSlice';
import searchReducer from './slices/searchSlice';

const rootReducer = combineReducers({
  AccessManagement: amReducer,
  notifications: notificationsReducer,
  menu: menuReducer,
  status:statusSlice,
  modal: modalSlice,
  formBuilder: formBuilderReducer,
  loading: loadingReducer,
  search: searchReducer,
});

export default rootReducer;

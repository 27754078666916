import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import './dataTable.scss';
import filterUnwantedFields from '../../../utils/filterUnwantedFields';

function LovDataTable({ rows, columns, viewParams, onSelect }) {
  const navigate = useNavigate();
  const [editRowsModel, setEditRowsModel] = useState({});

  const filteredRows = filterUnwantedFields(rows);
  const rowsWithIds = addUniqueIdsToRows(filteredRows);

  const handleRowClick = (event) => {
    const id = event.row.id;

    if (viewParams && viewParams.page) {
      navigate(`/${viewParams.page}/${id}`);
    } else if (onSelect) {
      onSelect(event.row);
    }
  };

  const handleEditRowsModelChange = (model) => {
    setEditRowsModel(model);
  };

  function addUniqueIdsToRows(rows) {
    return rows.map((row, index) => ({
      ...row,
      id: index.toString(),
    }));
  }

  return (
    <div className='dataTable'>
      <DataGrid
        className='dataGrid'
        rows={rowsWithIds}
        columns={columns}
        initialState={{
          density: 'compact',
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarQuickFilter />
            </GridToolbarContainer>
          ),
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        disableColumnFilter
        disableDensitySelector
        disableColumnSelector
        disableColumnMenu
        onRowClick={handleRowClick}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
      />
    </div>
  );
}

export default LovDataTable;

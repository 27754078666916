import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	useCallback,
} from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { debounce } from "lodash";

const ResponsiveGridLayout = WidthProvider(Responsive);

// Helper to save layouts to localStorage
const saveToLocalStorage = (key, value) => {
	if (global.localStorage) {
		global.localStorage.setItem(key, JSON.stringify(value));
	}
};

// Helper to get layouts from localStorage
const getFromLocalStorage = (key) => {
	if (global.localStorage) {
		const saved = global.localStorage.getItem(key);
		return saved ? JSON.parse(saved) : null;
	}
	return null;
};
const ReusableContainer = ({
	layoutKey = "layout",
	initialLayout = [],
	onLayoutChange,
	children,
	titles = [],
	cols = 12, // Reduce the number of columns to avoid overly wide layouts
	rowHeightRatio = 20, // Adjust this ratio based on how much vertical space you want
	isDraggable = true,
	isResizable = true,
	titleRender = null,
	gridWidthMultiplier = 6, // Set multiplier to a sensible value to prevent horizontal overflow
}) => {
	const [currentLayout, setCurrentLayout] = useState(
		getFromLocalStorage(layoutKey) || initialLayout || []
	);
	const containerRef = useRef(null);
	const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

	// Handle layout changes
	const handleLayoutChange = (newLayout) => {
		setCurrentLayout(newLayout);
		saveToLocalStorage(layoutKey, newLayout); // Save to localStorage
		if (onLayoutChange) {
			onLayoutChange(newLayout);
		}
	};

	const updateSize = useCallback(
		debounce(() => {
			if (containerRef.current) {
				setContainerSize({
					width: containerRef.current.offsetWidth,
					height: containerRef.current.offsetHeight,
				});
			}
		}, 100),
		[]
	);

	useEffect(() => {
		updateSize();
		window.addEventListener("resize", updateSize);
		return () => window.removeEventListener("resize", updateSize);
	}, [updateSize]);

	const childrenArray = useMemo(
		() => React.Children.toArray(children),
		[children]
	);

	const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
	const colsPerBreakpoint = { lg: 12, md: 10, sm: 8, xs: 4, xxs: 2 };

	return (
		<div ref={containerRef} style={{ width: "100%", height: "100%" }}>
			<ResponsiveGridLayout
				className="layout"
				layouts={{ lg: currentLayout }}
				breakpoints={breakpoints}
				cols={colsPerBreakpoint}
				rowHeight={Math.max(containerSize.height / rowHeightRatio, 100)} // Ensure minimum row height to avoid shrinking
				width={containerSize.width * gridWidthMultiplier}
				onLayoutChange={handleLayoutChange}
				draggableHandle=".draggable-handle"
				autoSize={true}
				isResizable={isResizable}
				isDraggable={isDraggable}
				compactType="horizontal"
			>
				{childrenArray.map((child, index) => (
					<div
						key={index}
						data-grid={
							currentLayout[index] || {
								i: index.toString(),
								x: 0,
								y: 0,
								w: 4,
								h: 3,
							}
						}
						style={{ overflow: "hidden" }}
					>
						<div
							className="draggable-handle"
							style={{
								padding: "4px",
								alignItems: "center",
								fontWeight: "bold",
								backgroundColor: "#F4A460",
								color: "white",
								cursor: isDraggable ? "move" : "default",
                                textAlign:"center"
							}}
						>
							{titleRender
								? titleRender(titles[index], index)
								: titles[index] || `Component ${index + 1}`}
						</div>
						<div style={{ width: "100%", height: "100%" }}>
							{React.cloneElement(child, {
								width:
									containerSize.width / colsPerBreakpoint.lg,
								height: containerSize.height,
							})}{" "}
							{/* Set dynamic size */}
						</div>
					</div>
				))}
			</ResponsiveGridLayout>
		</div>
	);
};
export default React.memo(ReusableContainer);

import React from 'react';
import { BarChart, PieChart, LineChart, Bar, Pie, Line, Cell, Tooltip, Legend, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const RechartsComponent = ({ component, chartData }) => {
    // Prepare Recharts data
    const xAxisKey = Object.keys(chartData[0])[0]; // First column as X-axis
    const dataKeys = Object.keys(chartData[0]).filter(key => key !== xAxisKey); // Remaining columns as datasets

    const rechartsBarData = chartData.map(item => ({
        name: item[xAxisKey] || "Unknown",
        ...dataKeys.reduce((acc, key) => {
            acc[key] = parseInt(item[key], 10) || 0;
            return acc;
        }, {}),
    }));

    const rechartsPieData = chartData.map(item => ({
        name: item[xAxisKey] || "Unknown",
        total: Object.values(item).reduce((sum, value) => sum + (parseInt(value, 10) || 0), 0), // Calculate total
    }));

    return (

        <div style={{ width: '100%', height: '300px' }}>
            {component.component_type === 'Bar Chart' && (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={rechartsBarData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {dataKeys.map((key, index) => (
                            <Bar
                                key={key}
                                dataKey={key}
                                fill={`rgba(${75 + index * 30}, ${192 - index * 30}, ${192 + index * 20}, 1)`}
                                // stackId="a" // Stacking the bars
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            )}

            {component.component_type === 'Pie Chart' && (
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={rechartsPieData}
                            dataKey="total"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                        >
                            {rechartsPieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={`rgba(${75 + index * 30}, ${192 - index * 30}, ${192 + index * 20}, 0.8)`} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            )}

            {component.component_type === 'Line Chart' && (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={rechartsBarData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            interval={0}  // Ensures all X-axis labels are displayed
                            tick={{ fontSize: 12 }} // Optional: Adjust label font size to prevent overlap
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {dataKeys.map((key, index) => (
                            <Line
                                key={key}
                                type="linear"  // Use "linear" instead of "monotone" to avoid smoothing
                                dataKey={key}
                                stroke={`rgba(${75 + index * 30}, ${192 - index * 30}, ${192 + index * 20}, 1)`}
                                dot={{ r: 4 }} // Ensure points are visible
                                activeDot={{ r: 6 }} // Increase the active dot size on hover
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>

    );
};

export default RechartsComponent;

import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import filterUnwantedFields from '../../../utils/filterUnwantedFields';
import './ListDataTable.css';
import { clearFilters, setFilters } from '../../../redux/slices/searchSlice';
import isEqual from 'lodash.isequal';
import { applyFilters } from '../../../utils/applyFilters';

function ListDataTable({ config }) {
  const {
    columns: inColumns,
    rows: inRows,
    uniqueIdColumn,
    rowCount = 0,
    paginationModel = { page: 0, pageSize: 10 },
    onPaginationModelChange,
    style,
    onRowClick,
    onSelectionModelChange,
    rowClassRules,
    onFilterModelChange,
    handleSearch,
    pageName
  } = config;
  const dispatch = useDispatch();

  const [rowsWithIds, setRowsWithIds] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const lastPage = useSelector((state) => state.search.lastPage);

  // Retrieve filters from Redux. Ensure it's an array.
  const globalFilters = useSelector((state) => state.search.filters) || [];
  const savedFilters = Array.isArray(globalFilters)
    ? globalFilters
    : (globalFilters.filters || []);
    
  const [filterModel, setFilterModel] = useState({ items: savedFilters });

  // Only update filterModel when savedFilters actually change.
  useEffect(() => {
    setFilterModel((prev) => {
      if (!isEqual(prev.items, savedFilters)) {
        return { items: savedFilters };
      }
      return prev;
    });
  }, [savedFilters]);

  useEffect(() => {
    if (lastPage !== pageName) {
      setFilterModel({ items: [] });
      dispatch(clearFilters())
    }
  }, [pageName]);

  useEffect(() => {
    const filteredRows = filterUnwantedFields(inRows);
    const updatedRows = filteredRows.map((row, index) => {
      const uniqueId = row[uniqueIdColumn]?.toString() || index.toString();
      return { ...row, id: uniqueId };
    });
    setRowsWithIds(updatedRows);
  }, [inRows, uniqueIdColumn]);

      const handleServerFilterChange = (filterModel) => {
        const localSearchFields = filterModel.items.reduce((acc, item) => {
          acc[item.field] = {
            operator: item.operator,
            value: item.value,
            value2: item.value2 || '',
          };
          return acc;
        }, {}); console.log('localSearchFields',localSearchFields);
        const filters = applyFilters(localSearchFields, inColumns, pageName, dispatch);
        // Trigger a new search query:
        handleSearch(filters);
      };
      
  // Append a new filter based on a suggestion string.
  function handleAutoSuggestFilter(suggestion) {
    const match = suggestion.match(/^Search\s+(\S+):\s+(.*)/);
    if (!match) return;
    const columnField = match[1];
    const filterValue = match[2];
    const newFilter = { field: columnField, operator: 'Like', value: filterValue };
    const newFilterModel = { items: [...filterModel.items, newFilter] };
    setFilterModel(newFilterModel);
    if (onFilterModelChange) {
      onFilterModelChange(newFilterModel);
    }
  }

  function CustomToolbar() {
    const dispatch = useDispatch();
    // const pageName = pageName;
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
      if (!inputValue) {
        setOptions([]);
      } else {
        const newOptions = inColumns.map((col) => ({
          field: col.field,
          headerName: col.headerName,
          typed: inputValue,
        }));
        setOptions(newOptions);
      }
    }, [inputValue, inColumns]);

    const handleDeleteFilter = (index) => {
      const newItems = filterModel.items.filter((item, i) => i !== index);
      const newFilterModel = { items: newItems };
      setFilterModel(newFilterModel);
      dispatch(setFilters({ filters: newFilterModel.items, page: pageName }));
      if (onFilterModelChange) {
        onFilterModelChange(newFilterModel);
      }
    };

    const handleSelectOption = (option) => {
      if (!option) return;
      const { field, typed } = option;
      const newFilter = { field, operator: 'Like', value: typed };
      const exists = filterModel.items.some((item) => item.field === field);
      let newFilterModel;
      if (!exists) {
        newFilterModel = { items: [...filterModel.items, newFilter] };
      } else {
        newFilterModel = {
          items: filterModel.items.map((item) =>
            item.field === field ? newFilter : item
          ),
        };
      }
      setFilterModel(newFilterModel);
      dispatch(setFilters({ filters: newFilterModel.items, page: pageName }));
      if (handleServerFilterChange) {
        handleServerFilterChange(newFilterModel);
      }
      setInputValue('');
    };

    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', ml: 2 }}>
          {filterModel.items.map((item, index) => {
            const col = inColumns.find((c) => c.field === item.field);
            const colLabel = col?.headerName || item.field;
            return (
              <Chip
                key={index}
                label={`Search ${colLabel}: ${item.value}`}
                onDelete={() => handleDeleteFilter(index)}
              />
            );
          })}
        </Box>
        <Autocomplete
          freeSolo
          options={options}
          getOptionLabel={(option) =>
            typeof option === 'string'
              ? option
              : `Search ${option.headerName}: ${option.typed}`
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            if (newValue && typeof newValue === 'object') {
              handleSelectOption(newValue);
            }
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <strong>{option.headerName}</strong>: {option.typed}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Search..." variant="standard" size="small" />
          )}
          sx={{ width: 500, ml: 2 }}
        />
      </GridToolbarContainer>
    );
  }

  function getDynamicRowClassName(params) {
    const rules = rowClassRules || {};
    for (const [className, condition] of Object.entries(rules)) {
      if (condition(params.row)) return className;
    }
    return '';
  }

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': { color: 'text.secondary' },
        '& .textPrimary': { color: 'text.primary' },
        '& .MuiDataGrid-columnHeaders': { backgroundColor: '#e0e0e0 !important' },
        '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
      }}
    >
      <DataGrid
        rows={rowsWithIds}
        columns={inColumns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={(newModel) => {
          setFilterModel((prev) => {
            if (!isEqual(prev, newModel)) {
              if (onFilterModelChange) {
                onFilterModelChange(newModel);
              }
              return newModel;
            }
            return prev;
          });
        }}
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => {
          if (onPaginationModelChange) {
            onPaginationModelChange(model);
          }
        }}
        key={`${paginationModel.page}-${paginationModel.pageSize}`}
        editMode="row"
        slots={{ toolbar: CustomToolbar }}
        initialState={{ density: 'compact' }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        onRowClick={onRowClick}
        style={style}
        checkboxSelection
        keepNonExistentRowsSelected
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(newSelectionModel) => {
          setRowSelectionModel(newSelectionModel);
          if (onSelectionModelChange) {
            onSelectionModelChange(newSelectionModel);
          }
        }}
        getRowClassName={getDynamicRowClassName}
      />
    </Box>
  );
}

export default ListDataTable;

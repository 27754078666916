import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PartnerRoleTypesList from '../../modules/BusinessPartnerManagement/PartnerRoles/components/bpmPartnerRoleTypesList';
import PartnerRoleTypeView from '../../modules/BusinessPartnerManagement/PartnerRoles/components/bpmPartnerRoleTypeView';
import PartnerList from '../../modules/BusinessPartnerManagement/Partners/components/bpmPartnerList';
import PartnerView from '../../modules/BusinessPartnerManagement/Partners/components/bpmPartnerView';

const BusinessPartnerManagementRoutes = () => {
  return (
    <Routes>
        <Route index element={<PartnerRoleTypesList />} />

        {/* Partner Roles */}
        <Route path="partnerroletypes" element={<PartnerRoleTypesList />} />
        <Route path="partnerroletypeview/:role_id" element={<PartnerRoleTypeView />} />
        <Route path="partnerroletypeview/create" element={<PartnerRoleTypeView />} />

        {/* Partners */}
        <Route path="partners" element={<PartnerList />} />
        <Route path="partnerview/:partner_id" element={<PartnerView />} />
        <Route path="partnerview/create" element={<PartnerView />} />

    </Routes>
  );
};

export default BusinessPartnerManagementRoutes;

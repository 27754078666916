import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import EditableTableV2 from '../../../../components/fwk/dataGrid/EditableTableV2';

const PartnerRelationshipsTab = ({ data, setData }) => {
    const tableRef = useRef(null);
    const [rows, setRows] = useState(data || []);
    const [deletedRows, setDeletedRows] = useState([]);

    useEffect(() => {
        setRows(data || []);
    }, [data]);

    const handleRowsChange = (updatedRows) => {
        setRows(updatedRows);
    };

    const handleDeleteRows = (selectedRowIds) => {
        setDeletedRows(prev => [...prev, ...rows.filter(row => selectedRowIds.includes(row.id))]);
        setRows(rows.filter(row => !selectedRowIds.includes(row.id)));
    };

    const columns = useMemo(() => [
        { field: 'relationship_type', label: 'Relationship Type', width: 200, inputType: 'text' },
        { field: 'related_partner_id', label: 'Related Partner ID', width: 150, inputType: 'number' },
        { field: 'hierarchy_level', label: 'Hierarchy Level', width: 150, inputType: 'number' },
        { field: 'is_active', label: 'Active', width: 100, inputType: 'checkbox' },
    ], []);

    return (
        <Box>
            <EditableTableV2
                ref={tableRef}
                rows={rows}
                columns={columns}
                onRowsChange={handleRowsChange}
                onDeleteRows={handleDeleteRows}
            />
        </Box>
    );
};

export default PartnerRelationshipsTab;

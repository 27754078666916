import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement, // Import PointElement for charts with points (e.g., LineChart)
    LineElement,  // Import LineElement for LineChart
    BarElement,   // Import BarElement for BarChart
    ArcElement,   // For Pie and Doughnut charts
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar as ChartJSBar, Line as ChartJSLine, Pie as ChartJSPie } from 'react-chartjs-2';

// Register the necessary Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,  // Register PointElement to avoid "point is not a registered element" error
    LineElement,
    BarElement,
    ArcElement,
    Tooltip,
    Legend
);

const ChartJSComponent = ({ component, chartData }) => {
    const xAxisKey = Object.keys(chartData[0])[0];
    const dataKeys = Object.keys(chartData[0]).filter(key => key !== xAxisKey);

    const labels = chartData.map(item => item[xAxisKey] || "Unknown");

    const chartJSDatasets = dataKeys.map((key, index) => ({
        label: key,
        data: chartData.map(item => parseInt(item[key], 10) || 0),
        backgroundColor: `rgba(${75 + index * 30}, ${192 - index * 30}, ${192 + index * 20}, 0.6)`,
        borderColor: `rgba(${75 + index * 30}, ${192 - index * 30}, ${192 + index * 20}, 1)`,
        borderWidth: 1,
        fill: false,
    }));

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false, // Ensure the chart fits within the available space
        plugins: {
            legend: { position: 'bottom' },
        },
    };

    return (
        <div style={{width:"100%", height:"100%"}}>
            {component.component_type === 'Bar Chart' && (
                <ChartJSBar data={{ labels, datasets: chartJSDatasets }} options={chartOptions} />
            )}

            {component.component_type === 'Pie Chart' && (
                <ChartJSPie data={{ labels, datasets: chartJSDatasets }} options={chartOptions} />
            )}

            {component.component_type === 'Line Chart' && (
                <ChartJSLine data={{ labels, datasets: chartJSDatasets }} options={chartOptions} />
            )}
        </div>
    );
};

export default ChartJSComponent;

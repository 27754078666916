import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import RechartsComponent from '../../../../components/fwk/charts/RechartsComponent';
import ChartJSComponent from '../../../../components/fwk/charts/ChartJSComponent';
import moduleDashboardQueries from '../graphql/dashModuleDashboardQueries';

const DashboardComponent = ({ component, chartLibrary = 'Recharts' }) => {
    const [fetchComponentData, { loading, data }] = useLazyQuery(moduleDashboardQueries.GET_COMPONENT_DATA, { fetchPolicy: 'network-only', });
    const [layout, setLayout] = useState([{ i: '0', x: 0, y: 0, w: 6, h: 4 }]); // Default size and position

    useEffect(() => {
        fetchComponentData({ variables: { component_id: component.component_id } });
    }, [component.component_id]);

    if (loading) return <CircularProgress />;

    const chartData = data ? data.getComponentData.data : [];

    if (chartData.length === 0) {
        return <p>No data available</p>;
    }

    return (
        <Box>
            {chartLibrary === 'ChartJS' ? (
                <ChartJSComponent
                    component={component}
                    chartData={chartData} // Pass raw chart data
                />
            ) : (
                <RechartsComponent
                    component={component}
                    chartData={chartData} // Pass raw chart data
                />
            )}
        </Box>
    );
};

export default DashboardComponent;

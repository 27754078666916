import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import moduleDashboardQueries from '../graphql/dashModuleDashboardQueries';
import DashboardComponent from './dashDashboardComponent'; // Reusable component for individual dashboard components
import ReusableContainer from '../../../../components/fwk/ReUsableContainer/ReusableContaner';

const ModuleDashboard = ({ module_code }) => {
    const [dashboardComponents, setDashboardComponents] = useState([]);
    const { loading, error, data } = useQuery(moduleDashboardQueries.GET_MODULE_DASHBOARD, {
        variables: { module_code }
    });

    useEffect(() => {
        if (data && data.getModuleDashboard) {
            setDashboardComponents(data.getModuleDashboard.components); // Set components metadata
        }
    }, [data]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error loading dashboard</p>;

    const titles = dashboardComponents.map((component) => component.component_name); // Extract titles

    return (
        <Box>
            <ReusableContainer layout={[]} titles={titles} onLayoutChange={() => {}}>
                {dashboardComponents.map((component) => (
                    <DashboardComponent 
                        key={component.component_id}
                        component={component} 
                        module_code={module_code}
                    />
                ))}
            </ReusableContainer>
        </Box>
    );
};

export default ModuleDashboard;

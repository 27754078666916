import { gql } from '@apollo/client';
import authorFragments from './pbmAuthorFragments';

const authorMutations = {
    CREATE_OR_UPDATE_AUTHOR: gql`
        mutation createOrUpdateAuthor($input: AuthorInput!) {
            createOrUpdateAuthor(input: $input) {
                ...AuthorFields
            }
        }
        ${authorFragments.allAuthorFields}
    `,
    DELETE_AUTHOR: gql`
        mutation deleteAuthor($author_id: Int!) {
            deleteAuthor(author_id: $author_id)
        }
    `,
};

export default authorMutations;

import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import './ResponsiveTabs.css';

const ResponsiveTabs = ({ tabs }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                className="responsive-tabs"
            >
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.title}
                        className={`responsive-tab ${selectedTab === index ? 'responsive-tab-selected' : ''} responsive-tab-hover responsive-tab-divider`}
                        disabled={!tab.visible}
                    />
                ))}
            </Tabs>
            <Box className="responsive-tab-content">
                {tabs[selectedTab].content}
            </Box>
        </Box>
    );
};

export default ResponsiveTabs;

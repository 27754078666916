import React, { useState, useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Box } from '@mui/material';

const customTheme = createTheme({
    components: {
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    color: '#1565c0',
                    borderWidth: '1px',
                    borderColor: '#2196f3',
                    border: '1px solid',
                    backgroundColor: '#90caf9',
                },
                labelContainer: {
                    color: '#fff',
                    fontSize: '1.2rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '15px',
                    marginBottom: '0px',
                    marginTop: '8px !important'
                },
                input: {
                    marginTop: '4px'
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '8px !important',
                },
            },
        },
    },
});

const InteractiveDatePicker = ({ name, value, fieldWidth = '70%', onChange, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const isClosing = useRef(false); // Track if the calendar is closing

    const handleOpen = () => {
        if (!isClosing.current) {
            setIsOpen(true); // Open the calendar if it's not in the process of closing
        }
    };

    const handleClose = () => {
        isClosing.current = true; // Set the closing flag
        setIsOpen(false); // Close the calendar
        setTimeout(() => {
            isClosing.current = false; // Reset the closing flag after a short delay
        }, 200); // Adjust delay as needed
    };

    const handleDateChange = (newValue) => {
        const formattedDate = newValue ? moment(newValue).format('DD-MM-YYYY') : null;
        onChange(name, formattedDate);
        handleClose(); // Ensure the calendar closes after selection
    };

    return (
        <ThemeProvider theme={customTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box sx={{ width: fieldWidth }}>
                    <DatePicker
                        value={value ? moment(value, 'DD-MM-YYYY') : null}
                        fullWidth
                        open={isOpen} // Controlled open state
                        onOpen={handleOpen} // Open handler
                        onClose={handleClose} // Close handler
                        onChange={handleDateChange} // Handle date selection and close calendar
                        slotProps={{
                            textField: {
                                onClick: handleOpen, // Open calendar on field click
                                onFocus: handleOpen, // Open calendar on tab focus
                                ...props, // Pass additional props to the text field
                            },
                        }}
                        format="DD/MM/YYYY"
                    />
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default InteractiveDatePicker;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, TextField, Box } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import bpmPartnerRoleTypesQueries from '../graphql/bpmPartnerRoleTypesQueries';
import bpmPartnerRoleTypesMutations from '../graphql/bpmPartnerRoleTypesMutations';
import ConfirmModal from '../../../../components/fwk/Modals/ConfirmModal';
import { showStatus } from '../../../../redux/slices/statusSlice';
import { useDispatch } from 'react-redux';
import ActionButtonGroup from '../../../../components/fwk/ActionButtonGroup/ActionButtonGroup';
import validateForm from '../../../../components/fwk/Validations/validationFramework';
import PageHeader from '../../../../components/fwk/PageHeader/PageHeader';
import filterUnwantedFields from '../../../../utils/filterUnwantedFields';

function PartnerRoleTypeView() {
    const { role_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isNew = !role_id;
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);


    const [formValues, setFormValues] = useState({
        role_name: '',
        description: '',
    });

    const { loading, error, data } = useQuery(bpmPartnerRoleTypesQueries.GET_PARTNER_ROLE_TYPE_BY_ID, {
        variables: { role_id: parseInt(role_id, 10) },
        skip: isNew,
        fetchPolicy: 'network-only',
    });

    const [createRoleType] = useMutation(bpmPartnerRoleTypesMutations.CREATE_PARTNER_ROLE_TYPE);
    const [updateRoleType] = useMutation(bpmPartnerRoleTypesMutations.UPDATE_PARTNER_ROLE_TYPE);

    useEffect(() => {
        if (data && data.getPartnerRoleTypeById) {
            setFormValues(data.getPartnerRoleTypeById);
        }
    }, [data]);

    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const validationSchema = {
        role_name: ['required'],
    };

    const handleSubmit = async () => {
        const errors = validateForm(formValues, validationSchema, dispatch);
        if (Object.keys(errors).length > 0) {
            return;
        }
        setOpenConfirmationModal(true);
    };

    const handleConfirm = async () => {
        const input = filterUnwantedFields(formValues, ['__typename'])
        try {
            if (isNew) {
                await createRoleType({ variables: { input: input } });
            } else {
                await updateRoleType({ variables: { input: { role_id: parseInt(role_id, 10), ...input } } });
            }
            setOpenConfirmationModal(false);
            dispatch(showStatus({ message: 'Role Type saved successfully', severity: 'success' }));
            navigate('/bpm/partnerroletypes');
        } catch (error) {
            setOpenConfirmationModal(false);
            dispatch(showStatus({ message: `Failed to save: ${error.message}`, severity: 'error' }));
        }
    };

    const buttonsConfig = {
        save: { label: 'Save' },
        cancel: { label: 'Cancel' },
    };

    const handleClose = () => {
        navigate('/bpm/partnerroletypes');
    };

    return (
        <Container>
            <PageHeader
                title="Partner Role Type Details"
                buttonsConfig={buttonsConfig}
                onSave={handleSubmit}
                onCancel={handleClose}
                showStatus={true}
            />
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="standard"
                        label="Role Name"
                        name="role_name"
                        value={formValues.role_name}
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        label="Description"
                        name="description"
                        value={formValues.description}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={3}
                    />
                </Grid>
            </Grid>
            <ConfirmModal
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                onConfirm={handleConfirm}
                message="Do you want to save the changes?"
            />
        </Container>
    );
}

export default PartnerRoleTypeView;

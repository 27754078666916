import { gql } from '@apollo/client';
import publisherFragments from './pbmPublisherFragments';

const publisherQueries = {
    GET_PUBLISHER_BY_ID: gql`
        query getPublisherById($publisher_id: Int!) {
            getPublisherById(publisher_id: $publisher_id) {
                ...PublisherFields
            }
        }
        ${publisherFragments.allPublisherFields}
    `,
    GET_ALL_PUBLISHERS: gql`
        query getAllPublishers(
            $page: Int
            $pageSize: Int
            $filters: [SearchFilterInput]
            $orderBy: String
            $orderDirection: String
        ) {
            getAllPublishers(
                page: $page
                pageSize: $pageSize
                filters: $filters
                orderBy: $orderBy
                orderDirection: $orderDirection
            ) {
                publishers {
                    ...PublisherFields
                }
                totalCount
            }
        }
        ${publisherFragments.allPublisherFields}
    `,
};

export default publisherQueries;

import React, { useState } from 'react';
import { Box } from '@mui/material';
import ModuleDashboard from '../Dashboards/components/dashModuleDashboard'; // Module-level dashboard
import ResponsiveTabs from '../../../components/fwk/Tabs/TabComponent'; // Your existing tab component

const DefaultAppDashboard = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    // Hardcoded module codes for the tabs
    const modules = [
        { module_code: 'CIC', module_name: 'Circulation' },
        // { module_code: 'HR', module_name: 'Human Resource' },
    ];

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box>
            <ResponsiveTabs
                value={selectedTab}
                onChange={handleTabChange}
                tabs={modules.map((module, index) => ({
                    title: module.module_name,
                    content: <ModuleDashboard module_code={module.module_code} key={index} />
                }))}
            />
        </Box>
    );
};

export default DefaultAppDashboard;

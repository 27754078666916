import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { ApolloClient, useMutation } from '@apollo/client';
import { userMutations } from '../../../modules/AccessManagement/Users/graphql/amUserMutations';
import { updateUserContext } from '../../../modules/AccessManagement/redux/amActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItemSelect from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { setContext } from '@apollo/client/link/context';
import { jwtDecode } from 'jwt-decode';

export default function ProfileMenu() {
    const [logoutUserMutation] = useMutation(userMutations.LOGOUT_USER_MUTATION);
    const [updateUserContextMutation] = useMutation(userMutations.UPDATE_USER_CONTEXT_MUTATION);

    const user = JSON.parse(localStorage.getItem('user'));
    const organizations = user?.organizations || [];
    const branches = user?.branches || [];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ✅ Determine default organization
    const selectedDefaultOrg =
        user?.organizations.length === 1
            ? user.organizations[0].organization_id  // If only one, use it
            : user?.organizations.find(org => org.default === 'Y')?.organization_id || user?.organizations[0]?.organization_id;

    // ✅ Determine default branch (if needed)
    const selectedDefaultBranch = user?.default_branch_id || '';

    // ✅ Set default organization & branch from user object
    const [selectedOrg, setSelectedOrg] = useState(selectedDefaultOrg || '');
    const [selectedBranch, setSelectedBranch] = useState(selectedDefaultBranch);

    const handleProfileClick = (popupState) => {
        if (user?.user_id) {
            popupState.close(); // ✅ Close the menu after navigation
            navigate(`/am/users/userView/${user.user_id}`);
        }
    };

    const handleOrgChange = async (event, popupState) => {
        const newOrgId = event.target.value;
        setSelectedOrg(newOrgId);

        try {
            // ✅ Call API to update user context
            const { data } = await updateUserContextMutation({
                variables: { default_organization_id: newOrgId },
            });

            if (!data?.updateUserContext?.success) {
                console.error("❌ Failed to update organization:", data?.updateUserContext?.message);
                return;
            }

            const newToken = data.updateUserContext.token;

            // ✅ Retrieve the existing user from localStorage
            let existingUser = JSON.parse(localStorage.getItem('user')) || {};

            // ✅ Update only the organization ID while keeping other details unchanged
            const updatedUser = { ...existingUser, default_organization_id: newOrgId };

            // ✅ Store the new JWT token and updated user in localStorage
            localStorage.setItem('token', newToken);
            localStorage.setItem('user', JSON.stringify(updatedUser));

            // ✅ Dispatch only the required updates to Redux
            dispatch(updateUserContext({ default_organization_id: newOrgId }));

            // ✅ Close the popup after a successful update
            popupState.close();
        } catch (error) {
            console.error("❌ Error updating organization:", error);
        }
    };
    const handleBranchChange = async (event, popupState) => {
        const newBranchId = event.target.value;
        setSelectedBranch(newBranchId);

        try {
            // ✅ Call API to update user context
            const { data } = await updateUserContextMutation({
                variables: { default_branch_id: newBranchId },
            });

            if (!data?.updateUserContext?.success) {
                console.error("❌ Failed to update branch:", data?.updateUserContext?.message);
                return;
            }

            const newToken = data.updateUserContext.token;

            // ✅ Retrieve the existing user from localStorage
            let existingUser = JSON.parse(localStorage.getItem('user')) || {};

            // ✅ Update only the branch ID while keeping other details unchanged
            const updatedUser = { ...existingUser, default_branch_id: newBranchId };

            // ✅ Store the new JWT token and updated user in localStorage
            localStorage.setItem('token', newToken);
            localStorage.setItem('user', JSON.stringify(updatedUser));

            // ✅ Dispatch only the required updates to Redux
            dispatch(updateUserContext({ default_branch_id: newBranchId }));

            // ✅ Close the popup after a successful update
            popupState.close();
        } catch (error) {
            console.error("❌ Error updating branch:", error);
        }
    };

    const handleLogout = async () => {
        try {
            // Call GraphQL mutation to log out
            const response = await logoutUserMutation();

            if (response.data.logoutUser) {
                // Remove from localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.setItem('isLoggedIn', false);

                // Dispatch Redux action to clear user data
                dispatch({ type: 'LOGOUT' });

                // Navigate to login screen
                // navigate('/', { replace: true });

                // Force a reload to ensure full reset
                window.location.reload();
            }
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };


    return (
        <PopupState variant="popover" popupId="user-menu">
            {(popupState) => (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            cursor: 'pointer',
                        }}
                        {...bindTrigger(popupState)}
                    >
                        <Avatar
                            alt="User Avatar"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png"
                            sx={{ width: 40, height: 40 }}
                        />
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                            {user?.username}
                        </Typography>
                    </Box>

                    {/* Menu with Center Alignment */}
                    <Menu
                        {...bindMenu(popupState)}
                        sx={{ padding: '10px' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minWidth: 450,
                                gap: 1, // Add spacing between dropdowns
                                padding: '10px'
                            }}
                        >
                            <FormControl fullWidth sx={{ minWidth: 250 }}>
                                <InputLabel>Default Organization</InputLabel>
                                <Select
                                    variant="standard"
                                    value={selectedOrg}
                                    onChange={(event) => handleOrgChange(event, popupState)} // ✅ Pass popupState
                                >
                                    {organizations.map((org) => (
                                        <MenuItemSelect key={org.organization_id} value={org.organization_id}>
                                            {org.organization_name}
                                        </MenuItemSelect>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ minWidth: 250 }}>
                                <InputLabel>Default Branch</InputLabel>
                                <Select
                                    variant="standard"
                                    value={selectedBranch}
                                    onChange={(event) => handleBranchChange(event, popupState)} // ✅ Pass popupState

                                >
                                    {branches.map((branch) => (
                                        <MenuItemSelect key={branch.branch_id} value={branch.branch_id}>
                                            {branch.branch_name}
                                        </MenuItemSelect>
                                    ))}
                                </Select>
                            </FormControl>

                            <MenuItem
                                onClick={() => handleProfileClick(popupState)} // ✅ Call with popupState
                                sx={{ padding: '10px', fontWeight: 'bold', textAlign: 'right',color:'green' }}
                            >
                                My Profile
                            </MenuItem>

                            <MenuItem
                                onClick={handleLogout}
                                sx={{ padding: '10px', fontWeight: 'bold', color: 'red', textAlign: 'right' }}
                            >
                                Logout
                            </MenuItem>
                        </Box>
                    </Menu>
                </>
            )}
        </PopupState>
    );
}

// EditableTableV2.js
import React, {
    useImperativeHandle,
    forwardRef,
    useRef,
    useMemo,
    useCallback,
    useState,
    useEffect,
} from 'react';
import {
    Box,
    Button,
    TextField,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Grid,
    Toolbar,
    IconButton,
    TablePagination,
    Typography,
    Select,
    MenuItem,
    Menu,            // ---------- NEW CODE HERE ----------
    FormControlLabel, // ---------- NEW CODE HERE ----------
    Fab,
    ListItemIcon,
    ListItemText,
    List,
    ListItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { margin, styled } from '@mui/system';
import DynamicDropdown from '../dropDownList/DynamicDropDown';
import AutoCompleteTextField from '../AutoCompleteTextField/AutoCompleteTextField';
import { uploadFile, downloadFile } from '../../../utils/fileUtil';
import { useApolloClient, useMutation, gql } from '@apollo/client';
import { showStatus } from '../../../redux/slices/statusSlice';
import { useDispatch } from 'react-redux';
import DatePickerTextField from '../DatePicker/DatePickerTextField';
import { Typography as MuiTypography } from '@mui/material'; // Correct import for Typography
import { MoreVert } from '@mui/icons-material';

const UPLOAD_DOCUMENT_MUTATION = gql`
  mutation UploadDocument($filePath: String!) {
    uploadDocument(filePath: $filePath) {
      success
      message
      filePath
    }
  }
`;

// Styled Components
const StyledTableContainer = styled(TableContainer)({
    overflowX: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const StyledToolbar = styled(Toolbar)({
    minHeight: '32px',
});

const StyledTable = styled(Table)({
    flex: '1',
    tableLayout: 'fixed',
    width: '100%',
});

const StyledTableCell = styled(TableCell)({
    paddingTop: '0px',
    paddingBottom: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: 'none',
    '& .MuiInputBase-root': {
        width: '100%',
    },
    '& .MuiTypography-root': {
        fontSize: '10px',
    },
    '& .MuiFormControl': {
        fontSize: '10px !important',
    },
    '& .MuiTextField-root': {
        margin: '4px !important',
    },
    '& .MuiSelect-root': {
        margin: '0px !important',
    },
    '& .MuiInput-root': {
        margin: '0px !important',
    },
    '& .MuiFormControl-root': {
        margin: '0px !important',
    },
    '& .MuiInput-input': {
        margin: '0px !important',
    },
});

const StyledTableHeadCell = styled(StyledTableCell)({
    border: '1px solid #fff',
    backgroundColor: '#227DC4',
    position: 'relative',
    paddingTop: '0px',
    paddingBottom: '0px',
    '& .MuiTypography-root': {
        fontSize: '13px !important',
        fontWeight: 'bold',
        color: '#fff'
    },
    '& .MuiCheckbox-root': {
        fontSize: '13px !important',
        fontWeight: 'bold',
        color: '#fff'
    },
    '& .MuiTableSortLabel-icon': {
        fontSize: '13px !important',
        fontWeight: 'bold',
        color: '#fff !important'
    },
});

const StyledTypography = styled(MuiTypography)({
    width: '100% !important',
    fontSize: '12px !important',
    fontWeight: 'bold',
});

const StyledTextField = styled(TextField)({
    width: '100% !important',
    fontSize: '12px !important',
    '& .MuiInputBase-root': {
        width: '100%',
    },
});

const StyledSmallTextField = styled(TextField)({
    width: '100% !important',
    fontSize: '12px !important',
    '& .MuiInputBase-root': {
        width: '100%',
    },
    '& .MuiInputBase-input': {
        fontSize: '0.875rem',
    },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#BCD8ED !important',
        cursor: 'pointer',
    },
}));

// Utility function for shallow comparison
const hasRowsChanged = (prevRows, newRows) => {
    if (prevRows.length !== newRows.length) {
        return true;
    }
    for (let i = 0; i < prevRows.length; i++) {
        const prevRow = prevRows[i];
        const newRow = newRows[i];
        const keys = Object.keys(prevRow);
        for (const key of keys) {
            if (prevRow[key] !== newRow[key]) {
                return true;
            }
        }
    }
    return false;
};

const EditableTableV2 = forwardRef(
    (
        {
            rows = [],
            columns,
            groupBy,  // if user provides a default grouping
            onRowsChange,
            onDeleteRows,
            onTextFieldBlur,
            onDropdownSelect,
            onAutoCompleteSelect,
            onDateChange,
            onCheckboxChange,
            rowsPerPageOptions = [5, 10, 25],
            defaultRowsPerPage = 10,
            disableAdd = false,
            disableDelete = false,
            defaultRowsCount = 0, // Number of empty rows to add by default
        },
        ref
    ) => {

        const [selectedRows, setSelectedRows] = useState([]);
        const [editingRowId, setEditingRowId] = useState(null);
        const [searchTerm, setSearchTerm] = useState('');
        const [order, setOrder] = useState('asc');
        const [orderBy, setOrderBy] = useState(columns[0]?.field || '');
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
        const dispatch = useDispatch();
        const client = useApolloClient();
        const firstColumnRef = useRef(null);
        const [uploadDocument] = useMutation(UPLOAD_DOCUMENT_MUTATION);

        // ---------- NEW CODE HERE ----------
        // Instead of using `columns` directly, we store them in state
        // so that we can dynamically update each column’s `hidden` property.
        const [columnsState, setColumnsState] = useState(columns);

        // We also keep a local `groupByField` to handle dynamic grouping from the menu
        const [groupByField, setGroupByField] = useState(groupBy?.field ?? '');

        // Column settings menu anchor + open state
        const [anchorEl, setAnchorEl] = useState(null);
        const openColumnMenu = Boolean(anchorEl);

        const handleOpenColumnMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleCloseColumnMenu = () => {
            setAnchorEl(null);
        };

        // Toggle a column’s hidden property
        const handleToggleColumn = (field) => {
            setColumnsState((prev) =>
                prev.map((col) => {
                    if (col.field === field) {
                        return { ...col, hidden: !col.hidden };
                    }
                    return col;
                })
            );
        };

        // Update grouping column
        const handleSelectGroupColumn = (event) => {
            const value = event.target.value;
            setGroupByField(value);
        };

        // State to manage column widths
        const [columnWidths, setColumnWidths] = useState(() => {
            const initialWidths = {};
            columns.forEach((col) => {
                initialWidths[col.field] = col.width || 150;
            });
            return initialWidths;
        });

        // Update columnWidths if the incoming `columns` prop changes
        useEffect(() => {
            const updatedWidths = { ...columnWidths };
            columns.forEach((col) => {
                if (!updatedWidths[col.field]) {
                    updatedWidths[col.field] = col.width || 150;
                }
            });
            setColumnWidths(updatedWidths);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [columns]);

        useImperativeHandle(ref, () => ({
            getRows: () => rows,
        }));

         // ---------- Handle Default Rows Initialization ----------
         const generateEmptyRow = () => {
            return {
                id: Date.now() + Math.random(), // Ensure unique ID for each row
                isNew: true,
                is_edited: 'N',
                databaseOperation: 'C',
                ...columns.reduce((acc, col) => ({ ...acc, [col.field]: '' }), {}),
            };
        };

        // Initialize rows with `defaultRowsCount` empty rows if rows are empty
        useEffect(() => {
            if (rows.length === 0 && defaultRowsCount > 0) {
                const initialRows = Array.from({ length: defaultRowsCount }, generateEmptyRow);
                onRowsChange(initialRows);
                if (initialRows.length > 0) {
                    setEditingRowId(initialRows[0].id); // Set the first row in edit mode
                }
            }
        }, [defaultRowsCount, onRowsChange, rows]);

        // Handler to add a new row
        const handleAddRow = useCallback(() => {
            const newRow = {
                id: Date.now(),
                isNew: true,
                is_edited: 'N',
                databaseOperation: 'C',
                ...columnsState.reduce((acc, col) => ({ ...acc, [col.field]: '' }), {}),
            };
            const updatedRows = [newRow, ...rows];
            onRowsChange(updatedRows);
            setEditingRowId(newRow.id);
            setPage(0);
        }, [columnsState, onRowsChange, rows]);

        // Handler to delete selected rows
        const handleDeleteRows = useCallback(async () => {
            if (onDeleteRows) {
                await onDeleteRows(selectedRows);
            }
            const updatedRows = rows.filter((row) => !selectedRows.includes(row.id));
            onRowsChange(updatedRows);
            setSelectedRows([]);
        }, [onDeleteRows, onRowsChange, rows, selectedRows]);

        // Handler to save row editing
        const handleSaveRow = useCallback(() => {
            const updatedRows = rows.map((row) =>
                row.id === editingRowId ? { ...row, isNew: false } : row
            );
            onRowsChange(updatedRows);
            setEditingRowId(null);
        }, [editingRowId, onRowsChange, rows]);

        // Handler for text field changes
        const handleChange = useCallback(
            (id, field, value, numberType) => {
                let processedValue = value;
                if (numberType === 'float') {
                    processedValue = parseFloat(value);
                    if (isNaN(processedValue)) {
                        processedValue = 0;
                    }
                } else if (numberType === 'int') {
                    processedValue = parseInt(value, 10);
                    if (isNaN(processedValue)) {
                        processedValue = 0;
                    }
                }
                const updatedRows = rows.map((row) => {
                    if (row.id === id) {
                        return {
                            ...row,
                            [field]: processedValue,
                            is_edited: 'Y',
                            databaseOperation: row.isNew ? 'C' : 'E',
                        };
                    }
                    return row;
                });
                if (hasRowsChanged(rows, updatedRows)) {
                    onRowsChange(updatedRows);
                }
                if (field.includes('date') && typeof onDateChange === 'function') {
                    onDateChange(id, field, processedValue);
                }
            },
            [onDateChange, onRowsChange, rows]
        );

        // Handler for dropdown changes
        const handleDropdownChange = useCallback(
            (id, field, selectedItem) => {
                const updatedRows = rows.map((row) => {
                    if (row.id === id) {
                        return {
                            ...row,
                            [field]: selectedItem ? selectedItem.value : '',
                            is_edited: 'Y',
                            databaseOperation: row.isNew ? 'C' : 'E',
                        };
                    }
                    return row;
                });
                if (hasRowsChanged(rows, updatedRows)) {
                    onRowsChange(updatedRows);
                }
                if (onDropdownSelect) {
                    onDropdownSelect(id, field, selectedItem);
                }
            },
            [onDropdownSelect, onRowsChange, rows]
        );

        // Handler for autocomplete changes
        const handleAutoCompleteChange = useCallback(
            (id, field, selectedItem) => {
                const updatedRows = rows.map((row) => {
                    if (row.id === id) {
                        return {
                            ...row,
                            [field]: selectedItem ? selectedItem.name : '',
                            is_edited: 'Y',
                            databaseOperation: row.isNew ? 'C' : 'E',
                            ...applyMappingForAutoComplete(columnsState, field, selectedItem),
                        };
                    }
                    return row;
                });
                if (hasRowsChanged(rows, updatedRows)) {
                    onRowsChange(updatedRows);
                }
                if (onAutoCompleteSelect) {
                    onAutoCompleteSelect(id, field, selectedItem);
                }
            },
            [onAutoCompleteSelect, onRowsChange, rows, columnsState]
        );

        const applyMappingForAutoComplete = (columns, field, selectedItem) => {
            const updatedFields = {};
            const column = columns.find((col) => col.field === field);
            if (column && column.mappingColumns) {
                column.mappingColumns.forEach((mapping) => {
                    if (typeof mapping === 'string') {
                        if (mapping.includes('.')) {
                            const parts = mapping.split('.');
                            const nestedValue = parts.reduce((acc, part) => acc && acc[part], selectedItem);
                            updatedFields[parts[parts.length - 1]] = nestedValue;
                        } else {
                            updatedFields[mapping] = selectedItem ? selectedItem[mapping] : '';
                        }
                    } else if (typeof mapping === 'object' && mapping.targetField) {
                        const { targetField, sourceField } = mapping;
                        if (sourceField && sourceField.includes('.')) {
                            const parts = sourceField.split('.');
                            const nestedValue = parts.reduce((acc, part) => acc && acc[part], selectedItem);
                            updatedFields[targetField] = nestedValue;
                        } else {
                            updatedFields[targetField] = selectedItem
                                ? selectedItem[sourceField || targetField]
                                : '';
                        }
                    }
                });
            } else {
                updatedFields[field] = selectedItem ? selectedItem[field] : '';
            }
            return updatedFields;
        };

        // Handler for checkbox changes
        const handleCheckboxChange = useCallback(
            (id, field) => {
                const updatedRows = rows.map((row) => {
                    if (row.id === id) {
                        const newValue = row[field] === 'Y' ? 'N' : 'Y';
                        return {
                            ...row,
                            [field]: newValue,
                            is_edited: 'Y',
                            databaseOperation: row.isNew ? 'C' : 'E',
                        };
                    }
                    return row;
                });
                if (hasRowsChanged(rows, updatedRows)) {
                    onRowsChange(updatedRows);
                }
                if (onCheckboxChange) {
                    const updatedRow = updatedRows.find((row) => row.id === id);
                    onCheckboxChange(id, field, updatedRow[field]);
                }
            },
            [onCheckboxChange, onRowsChange, rows]
        );

        // Handler for text field blur
        const handleTextFieldBlur = useCallback(
            (id, field, value) => {
                if (onTextFieldBlur) {
                    onTextFieldBlur(id, field, value);
                }
            },
            [onTextFieldBlur]
        );

        // Handler for search input
        const handleSearchChange = useCallback((event) => {
            setSearchTerm(event.target.value);
        }, []);

        // Handler for page changes
        const handleChangePage = useCallback((event, newPage) => {
            setPage(newPage);
        }, []);

        // Handler for rows per page changes
        const handleChangeRowsPerPage = useCallback((event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }, []);

        // Sorting
        const handleRequestSort = useCallback(
            (property) => {
                if (editingRowId !== null) {
                    handleSaveRow();
                }
                const isAscending = orderBy === property && order === 'asc';
                setOrder(isAscending ? 'desc' : 'asc');
                setOrderBy(property);
            },
            [editingRowId, handleSaveRow, order, orderBy]
        );

        // Select all
        const handleSelectAllClick = useCallback(
            (event) => {
                if (event.target.checked) {
                    const newSelecteds = rows.map((n) => n.id);
                    setSelectedRows(newSelecteds);
                    return;
                }
                setSelectedRows([]);
            },
            [rows]
        );

        // Click row to edit
        const handleRowClick = useCallback(
            (id) => {
                if (editingRowId !== null && editingRowId !== id) {
                    handleSaveRow();
                }
                setEditingRowId(id);
            },
            [editingRowId, handleSaveRow]
        );

        // File upload
        const handleFileUpload = useCallback(
            async (file, id, field) => {
                const filePath = await uploadFile(file, uploadDocument, dispatch, showStatus);
                if (filePath) {
                    const updatedRows = rows.map((row) =>
                        row.id === id ? { ...row, [field]: filePath, is_edited: 'Y' } : row
                    );
                    if (hasRowsChanged(rows, updatedRows)) {
                        onRowsChange(updatedRows);
                    }
                }
            },
            [dispatch, onRowsChange, rows, uploadDocument]
        );

        // File download
        const handleFileDownload = useCallback(
            async (filePath) => {
                await downloadFile(filePath, client, dispatch, showStatus);
            },
            [client, dispatch]
        );

        // Filter by search
        const filteredRows = useMemo(() => {
            return rows.filter((row) =>
                Object.values(row).some((value) =>
                    String(value).toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }, [rows, searchTerm]);

        // Sort rows
        const sortedRows = useMemo(() => {
            return [...filteredRows].sort((a, b) => {
              if (orderBy) {
                if (a[orderBy] < b[orderBy]) {
                  return order === 'asc' ? -1 : 1;
                }
                if (a[orderBy] > b[orderBy]) {
                  return order === 'asc' ? 1 : -1;
                }
              }
              return 0;
            });
          }, [filteredRows, order, orderBy]);
          

        // Paginate
        const paginatedRows = useMemo(() => {
            return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }, [sortedRows, page, rowsPerPage]);

        // Determine if a row is selected
        const isSelected = useCallback((id) => selectedRows.includes(id), [selectedRows]);

        // ---------- GROUPING LOGIC ----------
        // We use groupByField from our local state (instead of `groupBy?.field`)
        const computedGroupBy = groupByField
            ? { field: groupByField, title: groupBy?.title ?? 'Group' }
            : null;

        const groupedRows = useMemo(() => {
            if (!computedGroupBy || !computedGroupBy.field) return { '': rows };
            return rows.reduce((groups, row) => {
                const groupKey = row[computedGroupBy.field] || 'Undefined';
                if (!groups[groupKey]) {
                    groups[groupKey] = [];
                }
                groups[groupKey].push(row);
                return groups;
            }, {});
        }, [rows, computedGroupBy]);

        const [expandedGroups, setExpandedGroups] = useState(() => {
            if (!computedGroupBy || !computedGroupBy.field) return {};
            const initialState = {};
            Object.keys(groupedRows).forEach((group) => {
                initialState[group] = true;
            });
            return initialState;
        });

        useEffect(() => {
            if (!computedGroupBy || !computedGroupBy.field) return;
            const updatedState = {};
            Object.keys(groupedRows).forEach((group) => {
                updatedState[group] =
                    expandedGroups[group] !== undefined ? expandedGroups[group] : true;
            });
            setExpandedGroups(updatedState);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [groupedRows]);

        const handleToggleGroup = useCallback((group) => {
            setExpandedGroups((prev) => ({
                ...prev,
                [group]: !prev[group],
            }));
        }, []);

        // Column resizing
        const handleColumnResize = useCallback((field, newWidth) => {
            setColumnWidths((prevWidths) => ({
                ...prevWidths,
                [field]: newWidth >= 50 ? newWidth : 50,
            }));
        }, []);

        const handleMouseDown = useCallback(
            (e, field) => {
                e.preventDefault();
                const startX = e.clientX;
                const startWidth = columnWidths[field];
                const handleMouseMove = (eMove) => {
                    const deltaX = eMove.clientX - startX;
                    const newWidth = startWidth + deltaX;
                    handleColumnResize(field, newWidth);
                };
                const handleMouseUp = () => {
                    document.removeEventListener('mousemove', handleMouseMove);
                    document.removeEventListener('mouseup', handleMouseUp);
                };
                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);
            },
            [columnWidths, handleColumnResize]
        );

        // ---------- RENDER ----------
        // Filter out hidden columns from columnsState
        const visibleColumns = columnsState.filter((col) => !col.hidden);

        return (
            <Box>
                <StyledToolbar>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={2}>
                            <StyledSmallTextField
                                variant="standard"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: <SearchIcon />,
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<AddIcon />}
                                onClick={handleAddRow}
                                style={{ marginRight: '1rem' }}
                                variant="outlined"
                                size="small"
                                disabled={disableAdd}
                            >
                                Add
                            </Button>
                            <Button
                                startIcon={<DeleteIcon />}
                                onClick={handleDeleteRows}
                                disabled={disableDelete || selectedRows.length === 0}
                                variant="outlined"
                                color="secondary"
                                size="small"
                                sx={{ marginRight: '1rem' }}
                            >
                                Delete
                            </Button>

                            {/* ---------- NEW CODE HERE ----------
                                Button to toggle the column visibility & grouping menu
                            */}
                            <Fab
                                size="small"
                                onClick={handleOpenColumnMenu}
                                sx={{
                                    width: 28,
                                    height: 28,
                                    minHeight: 28,
                                    padding: 0,
                                    borderRadius: '50%',
                                    border: '1px solid',
                                    borderColor: 'primary.main',
                                    backgroundColor: 'transparent',
                                    color: 'primary.main',
                                    // or any other styling you want
                                }}
                            >
                                <MoreVert fontSize="small" />
                            </Fab>

                            <Menu
                                anchorEl={anchorEl}
                                open={openColumnMenu}
                                onClose={handleCloseColumnMenu}
                                PaperProps={{ style: { maxHeight: 350, width: '250px' } }}
                            >
                                <Box sx={{ p: 2 }}>
                                    <Typography variant="subtitle1">
                                        Group By
                                    </Typography>
                                    <Select
                                        fullWidth
                                        variant="standard"
                                        value={groupByField}
                                        onChange={handleSelectGroupColumn}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {columnsState.map((col) => (
                                            <MenuItem key={col.field} value={col.field}>
                                                {col.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                                        Show / Hide Columns
                                    </Typography>

                                    {/* A List is great for consistent alignment */}
                                    <List
                                        dense
                                        disablePadding
                                        sx={{ p: 0, m: 0 }}
                                    >
                                        {columnsState.map((col) => (
                                            <ListItem
                                                key={col.field}
                                                sx={{ p: 0, m: 0 }}
                                            >
                                                <ListItemIcon sx={{ minWidth: 0, mr: 0 }}>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={!col.hidden}
                                                        onChange={() => handleToggleColumn(col.field)}
                                                        size="small"
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={col.label}
                                                    primaryTypographyProps={{
                                                        variant: 'body2',
                                                        sx: { m: 0, p: 0 }, // Remove extra space in Typography
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>

                                </Box>
                            </Menu>
                        </Grid>
                    </Grid>
                </StyledToolbar>

                <StyledTableContainer
                    component={Paper}
                    style={{ backgroundColor: 'white', boxShadow: 'none', overflowX: 'auto' }}
                >
                    <StyledTable stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={
                                            selectedRows.length > 0 &&
                                            selectedRows.length < rows.length
                                        }
                                        checked={
                                            rows.length > 0 &&
                                            selectedRows.length === rows.length
                                        }
                                        onChange={handleSelectAllClick}
                                    />
                                </StyledTableHeadCell>
                                {visibleColumns.map((column) => (
                                    <StyledTableHeadCell
                                        key={column.field}
                                        sortDirection={orderBy === column.field ? order : false}
                                        style={{ width: columnWidths[column.field] }}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.field}
                                            direction={orderBy === column.field ? order : 'asc'}
                                            onClick={() => handleRequestSort(column.field)}
                                        >
                                            <StyledTypography>{column.label}</StyledTypography>
                                        </TableSortLabel>
                                        {/* Resize handle */}
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 0,
                                                height: '100%',
                                                width: '5px',
                                                cursor: 'col-resize',
                                                userSelect: 'none',
                                            }}
                                            onMouseDown={(e) => handleMouseDown(e, column.field)}
                                        />
                                    </StyledTableHeadCell>
                                ))}
                                <StyledTableHeadCell style={{ width: '100%' }} />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {computedGroupBy && computedGroupBy.field ? (
                                Object.keys(groupedRows).map((group) => (
                                    <React.Fragment key={group}>
                                        <TableRow>
                                            <TableCell
                                                colSpan={visibleColumns.length + 2}
                                                style={{
                                                    backgroundColor: '#d9edf7',
                                                    cursor: 'pointer',
                                                    padding: '0px',
                                                }}
                                                onClick={() => handleToggleGroup(group)}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    sx={{ padding: '0px' }}
                                                >
                                                    <IconButton size="small">
                                                        {expandedGroups[group] ? (
                                                            <ExpandLessIcon />
                                                        ) : (
                                                            <ExpandMoreIcon />
                                                        )}
                                                    </IconButton>
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{ fontWeight: 'bold' }}
                                                    >
                                                        {computedGroupBy.title
                                                            ? `${computedGroupBy.title}: ${group}`
                                                            : `${group}`}{' '}
                                                        ({groupedRows[group].length})
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>

                                        {expandedGroups[group] &&
                                            groupedRows[group].map((row, rowIndex) => (
                                                <StyledTableRow
                                                    key={row.id}
                                                    selected={isSelected(row.id)}
                                                    onClick={() => handleRowClick(row.id)}
                                                    style={{
                                                        backgroundColor:
                                                            row.is_edited === 'Y'
                                                                ? '#ffeeba'
                                                                : row.isNew
                                                                    ? '#fffbcc'
                                                                    : rowIndex % 2 === 0
                                                                        ? '#D2E5F3'
                                                                        : '#E0ECF6',
                                                    }}
                                                >
                                                    <StyledTableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isSelected(row.id)}
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedRows((prevSelected) =>
                                                                    prevSelected.includes(row.id)
                                                                        ? prevSelected.filter(
                                                                            (id) => id !== row.id
                                                                        )
                                                                        : [...prevSelected, row.id]
                                                                );
                                                            }}
                                                        />
                                                    </StyledTableCell>

                                                    {visibleColumns.map((column) => (
                                                        <StyledTableCell
                                                            key={column.field}
                                                            style={{
                                                                width: columnWidths[column.field],
                                                                borderBottom: 'none',
                                                            }}
                                                        >
                                                            {editingRowId === row.id ? (
                                                                column.inputType === 'dropdown' ? (
                                                                    column.options ? (
                                                                        <Select
                                                                            variant="standard"
                                                                            name={column.field}
                                                                            value={row[column.field] || ''}
                                                                            onChange={(e) => {
                                                                                const selectedOption =
                                                                                    column.options.find(
                                                                                        (option) =>
                                                                                            option.value ===
                                                                                            e.target.value
                                                                                    );
                                                                                handleDropdownChange(
                                                                                    row.id,
                                                                                    column.field,
                                                                                    selectedOption || {
                                                                                        value: e.target.value,
                                                                                        label: e.target.value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            size="small"
                                                                            fullWidth
                                                                            disabled={column.disabled}
                                                                        >
                                                                            {column.options.map(
                                                                                (option) => (
                                                                                    <MenuItem
                                                                                        key={
                                                                                            option.value
                                                                                        }
                                                                                        value={
                                                                                            option.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.label
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            )}
                                                                        </Select>
                                                                    ) : (
                                                                        <DynamicDropdown
                                                                            query={column.query}
                                                                            dataPath={column.dataPath}
                                                                            variables={column.variables}
                                                                            labelKey={column.labelKey}
                                                                            valueKey={column.valueKey}
                                                                            name={column.field}
                                                                            value={
                                                                                row[column.field] || ''
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleDropdownChange(
                                                                                    row.id,
                                                                                    column.field,
                                                                                    {
                                                                                        value:
                                                                                            e.target.value,
                                                                                        label: e.target
                                                                                            .innerText,
                                                                                    }
                                                                                )
                                                                            }
                                                                            disabled={column.disabled}
                                                                        />
                                                                    )
                                                                ) : column.inputType ===
                                                                    'autocomplete' ? (
                                                                    <AutoCompleteTextField
                                                                        initialValue={
                                                                            row[column.field] || ''
                                                                        }
                                                                        searchQuery={column.query}
                                                                        variables={column.variables}
                                                                        searchField={
                                                                            column.searchField
                                                                        }
                                                                        columns={column.columns}
                                                                        searchResultsField={
                                                                            column.searchResultsField
                                                                        }
                                                                        onSelect={(selectedItem) =>
                                                                            handleAutoCompleteChange(
                                                                                row.id,
                                                                                column.field,
                                                                                selectedItem
                                                                            )
                                                                        }
                                                                        modalTitle={column.label}
                                                                        disabled={column.disabled}
                                                                    />
                                                                ) : column.inputType ===
                                                                    'checkbox' ? (
                                                                    <Checkbox
                                                                    checked={row[column.field] === 'Y'}
                                                                    onChange={() => {
                                                                      if (column.onChange) {
                                                                        // Call the custom onChange from the column (e.g. from PartnerContactsTab)
                                                                        column.onChange(row.id || row.contact_id, row);
                                                                    } else {
                                                                        // Fallback to the internal handler
                                                                        handleCheckboxChange(row.id, column.field);
                                                                      }
                                                                    }}
                                                                    disabled={column.disabled}
                                                                    />
                                                                ) : column.inputType === 'date' ? (
                                                                    <DatePickerTextField
                                                                        name={column.field}
                                                                        value={row[column.field] || ''}
                                                                        onChange={(field, value) =>
                                                                            handleChange(
                                                                                row.id,
                                                                                field,
                                                                                value
                                                                            )
                                                                        }
                                                                        variant="standard"
                                                                        sx={{ mb: 2 }}
                                                                        fullWidth
                                                                        fieldWidth="100%"
                                                                    />
                                                                ) : (
                                                                    <StyledTextField
                                                                        variant="standard"
                                                                        value={
                                                                            row[column.field] || ''
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                row.id,
                                                                                column.field,
                                                                                e.target.value,
                                                                                column.numberType
                                                                            )
                                                                        }
                                                                        size="small"
                                                                        type="text"
                                                                        // style={{
                                                                        //     backgroundColor: 'white',
                                                                        // }}
                                                                        inputRef={
                                                                            columnsState.indexOf(
                                                                                column
                                                                            ) === 0
                                                                                ? firstColumnRef
                                                                                : null
                                                                        }
                                                                        onBlur={() =>
                                                                            handleTextFieldBlur(
                                                                                row.id,
                                                                                column.field,
                                                                                row[column.field]
                                                                            )
                                                                        }
                                                                        disabled={column.disabled}
                                                                    />
                                                                )
                                                            ) : (
                                                                row[column.field]
                                                            )}
                                                        </StyledTableCell>
                                                    ))}
                                                    <StyledTableCell style={{ width: '100%' }} />
                                                </StyledTableRow>
                                            ))}
                                    </React.Fragment>
                                ))
                            ) : (
                                // If no grouping
                                sortedRows.length > 0 ? (
                                    paginatedRows.map((row, rowIndex) => (
                                        <StyledTableRow
                                            key={row.id}
                                            selected={isSelected(row.id)}
                                            onClick={() => handleRowClick(row.id)}
                                            style={{
                                                backgroundColor:
                                                    row.is_edited === 'Y'
                                                        ? '#ffeeba'
                                                        : row.isNew
                                                            ? '#fffbcc'
                                                            : rowIndex % 2 === 0
                                                                ? '#D2E5F3'
                                                                : '#E0ECF6',
                                            }}
                                        >
                                            <StyledTableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isSelected(row.id)}
                                                    onChange={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedRows((prevSelected) =>
                                                            prevSelected.includes(row.id)
                                                                ? prevSelected.filter(
                                                                    (id) => id !== row.id
                                                                )
                                                                : [...prevSelected, row.id]
                                                        );
                                                    }}
                                                />
                                            </StyledTableCell>
                                            {visibleColumns.map((column) => (
                                                <StyledTableCell
                                                    key={column.field}
                                                    style={{
                                                        width: columnWidths[column.field],
                                                        borderBottom: 'none',
                                                    }}
                                                >
                                                    {editingRowId === row.id ? (
                                                        column.inputType === 'dropdown' ? (
                                                            column.options ? (
                                                                <Select
                                                                    variant="standard"
                                                                    name={column.field}
                                                                    value={row[column.field] || ''}
                                                                    onChange={(e) => {
                                                                        const selectedOption =
                                                                            column.options.find(
                                                                                (option) =>
                                                                                    option.value ===
                                                                                    e.target.value
                                                                            );
                                                                        handleDropdownChange(
                                                                            row.id,
                                                                            column.field,
                                                                            selectedOption || {
                                                                                value: e.target.value,
                                                                                label: e.target.value,
                                                                            }
                                                                        );
                                                                    }}
                                                                    size="small"
                                                                    fullWidth
                                                                    disabled={column.disabled}
                                                                >
                                                                    {column.options.map((option) => (
                                                                        <MenuItem
                                                                            key={option.value}
                                                                            value={option.value}
                                                                        >
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            ) : (
                                                                <DynamicDropdown
                                                                    query={column.query}
                                                                    dataPath={column.dataPath}
                                                                    variables={column.variables}
                                                                    labelKey={column.labelKey}
                                                                    valueKey={column.valueKey}
                                                                    name={column.field}
                                                                    value={row[column.field] || ''}
                                                                    onChange={(e) =>
                                                                        handleDropdownChange(row.id, column.field, {
                                                                            value: e.target.value,
                                                                            label: e.target.innerText,
                                                                        })
                                                                    }
                                                                    disabled={column.disabled}
                                                                />
                                                            )
                                                        ) : column.inputType === 'autocomplete' ? (
                                                            <AutoCompleteTextField
                                                                initialValue={row[column.field] || ''}
                                                                searchQuery={column.query}
                                                                variables={column.variables}
                                                                searchField={column.searchField}
                                                                columns={column.columns}
                                                                searchResultsField={column.searchResultsField}
                                                                onSelect={(selectedItem) =>
                                                                    handleAutoCompleteChange(
                                                                        row.id,
                                                                        column.field,
                                                                        selectedItem
                                                                    )
                                                                }
                                                                modalTitle={column.label}
                                                                disabled={column.disabled}
                                                            />
                                                        ) : column.inputType === 'checkbox' ? (
                                                            <Checkbox
                                                            checked={row[column.field] === 'Y'}
                                                            onChange={() => {
                                                              if (column.onChange) {
                                                                // Call the custom onChange from the column (e.g. from PartnerContactsTab)
                                                                column.onChange(row.id || row.contact_id, row);
                                                            } else {
                                                                // Fallback to the internal handler
                                                                handleCheckboxChange(row.id, column.field);
                                                              }
                                                            }}
                                                            disabled={column.disabled}
                                                            />
                                                        ) : column.inputType === 'date' ? (
                                                            <DatePickerTextField
                                                                name={column.field}
                                                                value={row[column.field] || ''}
                                                                onChange={(field, value) =>
                                                                    handleChange(row.id, field, value)
                                                                }
                                                                variant="standard"
                                                                sx={{ mb: 2 }}
                                                                fullWidth
                                                                fieldWidth="100%"
                                                            />
                                                        ) : (
                                                            <StyledTextField
                                                                variant="standard"
                                                                value={row[column.field] || ''}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        row.id,
                                                                        column.field,
                                                                        e.target.value,
                                                                        column.numberType
                                                                    )
                                                                }
                                                                size="small"
                                                                type="text"
                                                                // style={{ backgroundColor: 'white' }}
                                                                inputRef={
                                                                    columnsState.indexOf(column) === 0
                                                                        ? firstColumnRef
                                                                        : null
                                                                }
                                                                onBlur={() =>
                                                                    handleTextFieldBlur(
                                                                        row.id,
                                                                        column.field,
                                                                        row[column.field]
                                                                    )
                                                                }
                                                                disabled={column.disabled}
                                                            />
                                                        )
                                                    ) : (
                                                        row[column.field]
                                                    )}
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell style={{ width: '100%' }} />
                                        </StyledTableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={visibleColumns.length + 2}>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                height="100%"
                                            >
                                                <Typography variant="body1">
                                                    No rows to display
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}

                            {/* Dummy row to fill space */}
                            <TableRow>
                                <TableCell
                                    colSpan={visibleColumns.length + 2}
                                    style={{ borderBottom: 'none' }}
                                >
                                    <Box sx={{ height: '100%', backgroundColor: '#f8f9fa' }} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </StyledTable>
                </StyledTableContainer>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={computedGroupBy && computedGroupBy.field ? rows.length : sortedRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        );
    }
);

export default EditableTableV2;

import { gql } from '@apollo/client';
import authorFragments from './pbmAuthorFragments';

const authorQueries = {
    GET_AUTHOR_BY_ID: gql`
        query getAuthorById($author_id: Int!) {
            getAuthorById(author_id: $author_id) {
                ...AuthorFields
            }
        }
        ${authorFragments.allAuthorFields}
    `,
    GET_ALL_AUTHORS: gql`
        query getAllAuthors(
            $page: Int
            $pageSize: Int
            $filters: [SearchFilterInput]
            $orderBy: String
            $orderDirection: String
        ) {
            getAllAuthors(
                page: $page
                pageSize: $pageSize
                filters: $filters
                orderBy: $orderBy
                orderDirection: $orderDirection
            ) {
                authors {
                    ...AuthorFields
                }
                totalCount
            }
        }
        ${authorFragments.allAuthorFields}
    `,
};

export default authorQueries;

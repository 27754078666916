// src/router/routes/InventoryRoutes.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PublisherList from '../../modules/PublisherManagement/Publishers/components/pbmPublisherList';
import PublisherView from '../../modules/PublisherManagement/Publishers/components/pbmPublisherView';
import AuthorList from '../../modules/PublisherManagement/Authors/components/pbmAuthorList';
import AuthorView from '../../modules/PublisherManagement/Authors/components/pbmAuthorView';

const PublisherManagementRoutes = () => {
  return (
    <Routes>
        <Route index element={<PublisherList />} />

        {/* Publishers */}
        <Route path="publisher" element={<PublisherList />} />
        <Route path="publisher/:publisher_id" element={<PublisherView />} />
        <Route path="publisher/create" element={<PublisherView />} />

        {/* Authors */}
        <Route path="author" element={<AuthorList />} />
        <Route path="author/:author_id" element={<AuthorView />} />
        <Route path="author/create" element={<AuthorView />} />

        {/* Add more Publisher Management routes here */}
    </Routes>
  );
};

export default PublisherManagementRoutes;

import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Drawer,
  Button,
  IconButton,
  TextField,
  Grid,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DatePickerTextField from '../DatePicker/DatePickerTextField';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters, clearFilters } from '../../../redux/slices/searchSlice';
import isEqual from 'lodash.isequal';

function FloatingSearch({ columns, onSearch, pageName }) {
  const dispatch = useDispatch();

  // Get saved filters using a stable selector
  const globalFilters = useSelector((state) => state.search.filters) || [];
  const savedFilters = Array.isArray(globalFilters)
    ? globalFilters
    : (globalFilters.filters || []);
  const lastPage = useSelector((state) => state.search.lastPage);

  // Compute initial local state from savedFilters
  const initialSearchFields = useMemo(() => {
    return savedFilters.reduce((acc, filter) => {
      acc[filter.field] = {
        operator: filter.operator || '=',
        value: filter.value || '',
        value2: filter.value2 || '',
      };
      return acc;
    }, {});
  }, [savedFilters]);

  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [open, setOpen] = useState(false);

  // Only update local state when saved filters (computed) actually change.
  useEffect(() => {
    if (!open && !isEqual(initialSearchFields, searchFields)) {
      setSearchFields(initialSearchFields);
    }
  }, [initialSearchFields, open]);
  

  // If switching pages, clear local and Redux filters.
  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
    if (isOpen && lastPage !== pageName) {
      setSearchFields({});
      dispatch(clearFilters());
    }
  };

  const handleSearchFieldChange = (field, key, value) => {
    setSearchFields((prev) => ({
      ...prev,
      [field]: { ...prev[field], [key]: value },
    }));
  };

  const handleApplySearch = () => {
    const filters = Object.entries(searchFields)
      .map(([field, { operator, value, value2 }]) => {
        const column = columns.find((col) => col.field === field);
        if (!column) return null;
        return {
          field,
          fetchFrom: column.fetchFrom || '',
          operator: operator || '=',
          value:
            column.colType === 'date' && value
              ? moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : value,
          value2:
            column.colType === 'date' && value2
              ? moment(value2, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : value2,
        };
      })
      .filter((filter) => filter !== null);

    dispatch(setFilters({ filters, page: pageName }));
    onSearch(filters);
    setOpen(false);
  };

  const handleResetSearch = () => {
    setSearchFields({});
    dispatch(clearFilters());
    onSearch([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.altKey) {
      handleApplySearch();
    } else if (e.key === 'Enter' && e.altKey) {
      handleResetSearch();
    }
  };

  const renderSearchField = (col) => {
    const operator = searchFields[col.field]?.operator || '=';
    const value = searchFields[col.field]?.value || '';
    const value2 = searchFields[col.field]?.value2 || '';
    const availableOperators = col.operators || ['='];

    return (
      <Grid container spacing={1} alignItems="center" key={col.field} sx={{ ml: 1 }}>
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <Select
              variant="standard"
              value={operator}
              onChange={(e) =>
                handleSearchFieldChange(col.field, 'operator', e.target.value)
              }
              sx={{ mt: 1 }}
            >
              {availableOperators.map((op) => (
                <MenuItem key={op} value={op}>
                  {op}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {col.colType === 'date' ? (
          operator === 'Between' ? (
            <Grid item xs={8}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <DatePickerTextField
                  variant="standard"
                  name={`${col.field}-from`}
                  label="From"
                  value={value || null}
                  onChange={(field, newValue) =>
                    handleSearchFieldChange(col.field, 'value', newValue)
                  }
                />
                <DatePickerTextField
                  variant="standard"
                  name={`${col.field}-to`}
                  label="To"
                  value={value2 || null}
                  onChange={(field, newValue) =>
                    handleSearchFieldChange(col.field, 'value2', newValue)
                  }
                />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={8}>
              <DatePickerTextField
                variant="standard"
                name={col.field}
                label={col.headerName}
                value={value || null}
                onChange={(field, newValue) =>
                  handleSearchFieldChange(col.field, 'value', newValue)
                }
              />
            </Grid>
          )
        ) : operator === 'Between' ? (
          <Grid item xs={8}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <TextField
                fullWidth
                size="small"
                label="From"
                value={value}
                onChange={(e) =>
                  handleSearchFieldChange(col.field, 'value', e.target.value)
                }
                onKeyDown={handleKeyDown}
                variant="standard"
              />
              <TextField
                fullWidth
                size="small"
                label="To"
                value={value2}
                onChange={(e) =>
                  handleSearchFieldChange(col.field, 'value2', e.target.value)
                }
                onKeyDown={handleKeyDown}
                variant="standard"
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={8}>
            <TextField
              fullWidth
              size="small"
              label={col.headerName}
              value={value}
              onChange={(e) =>
                handleSearchFieldChange(col.field, 'value', e.target.value)
              }
              onKeyDown={handleKeyDown}
              variant="standard"
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      {!open && (
        <Box sx={{ position: 'fixed', top: 120, right: 20, zIndex: 1300 }}>
          <IconButton
            color="primary"
            onClick={toggleDrawer(true)}
            sx={{
              backgroundColor: 'white',
              border: '1px solid #ccc',
              '&:hover': { backgroundColor: '#f0f0f0' },
            }}
          >
            <SearchIcon />
          </IconButton>
        </Box>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{ sx: { zIndex: 1300 } }}
      >
        <Box sx={{ width: 300, p: 2 }}>
          <h3 style={{ marginBottom: '16px' }}>Search Filters</h3>
          <Grid container spacing={2}>
            {columns.map((col) => renderSearchField(col))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="outlined" onClick={handleResetSearch}>
              Reset
            </Button>
            <Button variant="contained" color="primary" onClick={handleApplySearch}>
              Search
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default FloatingSearch;

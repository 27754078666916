import React from 'react';
import { Button, Menu, MenuItem, Box, Fab } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const ActionButtonGroup = ({
    buttonsConfig,
    onSave,
    onCancel,
    onAdd,
    onDelete,
    actionsConfig,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleActionClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box display="flex" gap={2}>
            {actionsConfig && actionsConfig.length > 0 && (
                <>
                    <Fab
                        size="small"
                        onClick={handleActionClick}
                        color="primary"
                        sx={{
                            width: 28,
                            height: 28,
                            minHeight: 28,
                            padding: 0,
                            borderRadius: '50%', // Ensures it stays circular
                        }}
                    >
                        <MoreVert fontSize="small" />
                    </Fab>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleActionClose}
                    >
                        {actionsConfig.map((action, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    handleActionClose();
                                    if (!action.disabled) action.onClick(); // Prevent action if disabled
                                }}
                                disabled={action.disabled}
                                sx={{
                                    color: action.disabled ? 'text.disabled' : 'text.primary', // Adjust color
                                    opacity: action.disabled ? 0.5 : 1, // Optionally reduce opacity for disabled items
                                    cursor: action.disabled ? 'not-allowed' : 'pointer',
                                }}
                            >
                                {action.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
            {buttonsConfig.save && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    disabled={buttonsConfig.save.disabled}
                >
                    {buttonsConfig.save.label || 'Save'}
                </Button>
            )}
            {buttonsConfig.cancel && (
                <Button
                    variant="contained"
                    onClick={onCancel}
                    disabled={buttonsConfig.cancel.disabled}
                >
                    {buttonsConfig.cancel.label || 'Cancel'}
                </Button>
            )}
            {buttonsConfig.add && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onAdd}
                    disabled={buttonsConfig.add.disabled}
                >
                    {buttonsConfig.add.label || 'Add'}
                </Button>
            )}
            {buttonsConfig.delete && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onDelete}
                    disabled={buttonsConfig.delete.disabled}
                >
                    {buttonsConfig.delete.label || 'Delete'}
                </Button>
            )}

        </Box>
    );
};

export default ActionButtonGroup;
// src/router/AppRouter.js

import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from '../components/Layout/MainLayout';
import ProtectedRoute from '../components/security/ProtectedRoute';
import PageLoader from '../components/PageLoader';
import PublisherManagementRoutes from './routes/PublisherManagementRoutes';
import DefaultAppDashboard from '../modules/Dashboard/components/dashAppDefaultDashboard';
import BusinessPartnerManagementRoutes from './routes/BusinessPartnerManagementRoutes';

// Lazy-loaded feature modules
const AccessManagementRoutes = lazy(() => import('./routes/AccessManagementRoutes'));
const ApplicationManagementRoutes = lazy(() => import('./routes/ApplicationManagementRoutes'));
const HumanResourceRoutes = lazy(() => import('./routes/HumanResourceRoutes'));
const CirculationRoutes = lazy(() => import('./routes/CirculationRoutes'));
const ReportsRoutes = lazy(() => import('./routes/ReportsRoutes')); // If applicable
const WorkFlowManagementRoutes = lazy(() => import('./routes/WorkFlowManagementRoutes'));
const ApprovalManagementRoutes = lazy(() => import('./routes/ApprovalManagementRoutes'));
const SystemSettingsRoutes = lazy(() => import('./routes/SystemSettingsRoutes'));
const GeneralLedgerRoutes = lazy(() => import('./routes/GeneralLedgerRoutes'));
const InventoryRoutes = lazy(() => import('./routes/InventoryRoutes'));
const PartyManagementRoutes = lazy(() => import('./routes/PartyManagementRoutes'));
const DashboardRoutes = lazy(() => import('./routes/DashboardRoutes'));
const CodeGeneratorRoutes = lazy(() => import('./routes/CodeGeneratorRoutes'));

// Other lazy-loaded pages
const Home = lazy(() => import('../components/Layout/Home'));
const Unauthorized = lazy(() => import('../components/Layout/unauthorized'));
const NotFound = lazy(() => import('../components/Layout/NotFound'));

// Lazy-loaded LoginPage
const LoginPage = lazy(() => import('../modules/AccessManagement/Authentication/components/LoginPage'));

const AppRouter = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {/* Public Routes */}
        {/* <Route path="/unauthorized" element={<Unauthorized />} /> */}
        <Route path="/login" element={<LoginPage />} />

        {/* Protected Routes Wrapped with MainLayout */}
        <Route
          path="/"
          element={
            <ProtectedRoute>

              <MainLayout />
            </ProtectedRoute>
          }
        >
          {/* Home Page */}
          <Route index element={<DefaultAppDashboard />} />

          {/* Unauthorized Route Inside ProtectedRoute */}
          <Route path="/unauthorized" element={<Unauthorized />} />

          {/* Access Management Module */}
          <Route path="am/*" element={<AccessManagementRoutes />} />

          {/* Application Management Module */}
          <Route path="app/*" element={<ApplicationManagementRoutes />} />

          {/* Human Resource Module */}
          <Route path="hr/*" element={<HumanResourceRoutes />} />

          {/* Circulation Module */}
          <Route path="cic/*" element={<CirculationRoutes />} />

          {/* Reports Module */}
          <Route path="rep/*" element={<ReportsRoutes />} />

          {/* Workflow Management Module */}
          <Route path="wfm/*" element={<WorkFlowManagementRoutes />} />

          {/* Approval Management Module */}
          <Route path="apm/*" element={<ApprovalManagementRoutes />} />

          {/* System Settings Module */}
          <Route path="ss/*" element={<SystemSettingsRoutes />} />

          {/* General Ledger Module */}
          <Route path="accgl/*" element={<GeneralLedgerRoutes />} />

          {/* Inventory Module */}
          <Route path="inv/*" element={<InventoryRoutes />} />

          {/* Busiess Partner Management Module */}
          <Route path="bpm/*" element={<BusinessPartnerManagementRoutes />} />

          {/* Dashboard Module */}
          <Route path="dash/*" element={<DashboardRoutes />} />

          {/* Publisher Management Module */}
          <Route path="pbm/*" element={<PublisherManagementRoutes />} />

          {/* Code Generator Module */}
          <Route path="codegen/*" element={<CodeGeneratorRoutes />} />
          <Route path="naksha/*" element={<CodeGeneratorRoutes />} />
          <Route path="formslist" element={<CodeGeneratorRoutes />} />
          <Route path="preview" element={<CodeGeneratorRoutes />} />

          {/* 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Route>

        {/* Fallback Route for Undefined Paths */}
        {/* Note: If you have other public routes, define them here */}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;

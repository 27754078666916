import { validationTypes } from './validationRules';
import { showStatus } from '../../../redux/slices/statusSlice';

/**
 * Validate a single field based on the provided rules.
 * @param {any} fieldValue - The value of the field.
 * @param {Array} rules - The validation rules.
 * @returns {Array} - An array of error messages.
 */
const validateField = (fieldValue, rules) => {
    let errors = [];

    rules.forEach((rule) => {
        if (typeof rule === 'string' && validationTypes[rule]) {
            const error = validationTypes[rule](fieldValue);
            if (error) errors.push(error);
        } else if (Array.isArray(rule)) {
            const [ruleType, param] = rule;
            if (validationTypes[ruleType]) {
                const error = validationTypes[ruleType](param)(fieldValue);
                if (error) errors.push(error);
            }
        }
    });

    return errors;
};

/**
 * Validate an entire form based on the validation schema and dispatch status messages.
 * @param {Object} formValues - The form data.
 * @param {Object} validationSchema - The validation rules.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Object} - An object containing error messages.
 */
const validateForm = (formValues, validationSchema, dispatch) => {
    let errors = {};

    for (const fieldName in validationSchema) {
        const fieldRules = validationSchema[fieldName];
        const fieldErrors = validateField(formValues[fieldName], fieldRules);

        if (fieldErrors.length > 0) {
            errors[fieldName] = fieldErrors;
        }
    }

    if (Object.keys(errors).length > 0) {
        const errorMessage = Object.entries(errors)
            .map(([field, messages]) => `${field.replace(/_/g, ' ')} ${messages.join(', ')}`)
            .join(' | ');

        dispatch(showStatus({ message: `Validation Failed: ${errorMessage}`, severity: 'error' }));
    }

    return errors;
};

export default validateForm;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    TextField,
    Typography,
    Box,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import authorQueries from '../graphql/pbmAuthorQueries';
import authorMutations from '../graphql/pbmAuthorMutations';
import { showStatus } from '../../../../redux/slices/statusSlice';
import { useDispatch } from 'react-redux';
import ActionButtonGroup from '../../../../components/fwk/ActionButtonGroup/ActionButtonGroup';
import DatePickerTextField from '../../../../components/fwk/DatePicker/DatePickerTextField';

function AuthorView() {
    const { author_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        author_name: '',
        biography: '',
        date_of_birth: '',
        nationality: '',
        address: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        phone_numbers: [],
        emails: [],
    });

    const { data, loading, error } = useQuery(authorQueries.GET_AUTHOR_BY_ID, {
        variables: { author_id: parseInt(author_id, 10) },
        skip: !author_id,
    });

    const [createOrUpdateAuthor] = useMutation(authorMutations.CREATE_OR_UPDATE_AUTHOR);

    useEffect(() => {
        if (data && data.getAuthorById) {
            setFormValues(data.getAuthorById);
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (field, value) => {
        setFormValues((prev) => ({
            ...prev,
            [field]: value, // Dynamically update the field
        }));
    };

    const handleSubmit = async () => {
        try {
            const input = {
                ...formValues,
                phone_numbers: formValues.phone_numbers.map((num) => num.trim()).filter(Boolean), // Clean up phone numbers
                emails: formValues.emails.map((email) => email.trim()).filter(Boolean), // Clean up emails
            };
            await createOrUpdateAuthor({ variables: { input } });
            dispatch(showStatus({ message: 'Author saved successfully', severity: 'success' }));
            navigate('/pbm/authors');
        } catch (err) {
            dispatch(showStatus({ message: `Error saving author: ${err.message}`, severity: 'error' }));
        }
    };

    const handleClose = () => navigate('/pbm/author');

    const buttonsConfig = {
        save: { label: 'Save' },
        cancel: { label: 'Cancel' },
    };

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error: {error.message}</Typography>;

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">{author_id ? 'Edit Author' : 'Create Author'}</Typography>
                <Box>
                    <ActionButtonGroup
                        buttonsConfig={buttonsConfig}
                        onSave={() => handleSubmit()}
                        onCancel={handleClose}
                    />
                </Box>
            </Box>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Author Name"
                            name="author_name"
                            value={formValues.author_name}
                            onChange={handleChange}
                            fullWidth
                            required
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePickerTextField
                            label="Date of Birth"
                            name="date_of_birth"
                            value={formValues.date_of_birth || ''}
                            onChange={handleDateChange}
                            fullWidth
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Biography"
                            name="biography"
                            value={formValues.biography}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nationality"
                            name="nationality"
                            value={formValues.nationality}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Address"
                            name="address"
                            value={formValues.address}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="City"
                            name="city"
                            value={formValues.city}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="State"
                            name="state"
                            value={formValues.state}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Postal Code"
                            name="postal_code"
                            value={formValues.postal_code}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Country"
                            name="country"
                            value={formValues.country}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Phone Numbers (comma-separated)"
                            name="phone_numbers"
                            value={formValues.phone_numbers.join(', ')}
                            onChange={(e) =>
                                setFormValues((prev) => ({
                                    ...prev,
                                    phone_numbers: e.target.value.split(',').map((num) => num.trim()),
                                }))
                            }
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Emails (comma-separated)"
                            name="emails"
                            value={formValues.emails.join(', ')}
                            onChange={(e) =>
                                setFormValues((prev) => ({
                                    ...prev,
                                    emails: e.target.value.split(',').map((email) => email.trim()),
                                }))
                            }
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default AuthorView;

import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const DynamicDropdown = ({ 
  query, 
  variables, 
  labelKey, 
  valueKey, 
  label, 
  value, 
  onChange, 
  name, 
  dataPath, 
  initialValue, 
  disabled,
  required=false
}) => {
  const { loading, error, data } = useQuery(query, { variables });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data) {
      const items = dataPath 
        ? dataPath.split('.').reduce((acc, part) => acc && acc[part], data) 
        : data.getValueSetValueByCode || [];
      setOptions(items);
    }
  }, [data]);

  useEffect(() => {
    if (initialValue && options.length > 0) {
      const selectedItem = options.find(item => item[valueKey] === initialValue);
      if (selectedItem) {
        onChange({
          target: {
            name,
            value: initialValue,
            label: selectedItem ? selectedItem[labelKey] : '',
          }
        });
      }
    }
  }, [initialValue, options, onChange, name, labelKey, valueKey]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => {
          const selectedItem = options.find(item => item[valueKey] === e.target.value);
          onChange({
            target: {
              name,
              value: e.target.value,
              label: selectedItem ? selectedItem[labelKey] : '',
            }
          });
        }}
        name={name}
        variant='standard'
        sx={{ mb: 2 }}
        disabled={disabled} // Honor disabled prop
        required={required}
      >
        {options.map((item) => (
          <MenuItem key={item[valueKey]} value={item[valueKey]}>
            {item[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DynamicDropdown;

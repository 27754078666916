import { gql } from '@apollo/client';

export const valueSetValueFields = gql`
  fragment ValueSetValueFields on ValueSetValue {
    value_id
    valueset_id
    valueset_code
    value
    value_code
    description
  }
`;
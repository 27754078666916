import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import bpmPartnerRoleTypesQueries from '../graphql/bpmPartnerRoleTypesQueries';
import bpmPartnerRoleTypesMutations from '../graphql/bpmPartnerRoleTypesMutations';
import ListDataTable from '../../../../components/fwk/dataGrid/ListDataTable';
import FloatingSearch from '../../../../components/fwk/FloatingSearch/FloatingSearch';
import { showStatus } from '../../../../redux/slices/statusSlice';
import ActionButtonGroup from '../../../../components/fwk/ActionButtonGroup/ActionButtonGroup';
import debounce from 'lodash.debounce';
import PageHeader from '../../../../components/fwk/PageHeader/PageHeader';

function PartnerRoleTypesList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedRows, setSelectedRows] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const { loading, error, data, refetch } = useQuery(bpmPartnerRoleTypesQueries.GET_ALL_PARTNER_ROLE_TYPES, {
        fetchPolicy: 'network-only',
    });

    const [deletePartnerRoleType] = useMutation(bpmPartnerRoleTypesMutations.DELETE_PARTNER_ROLE_TYPE);

    const handleRowClick = useCallback((params) => {
        navigate(`/bpm/partnerroletypeview/${params.row.role_id}`);
    }, [navigate]);

    const handleRowSelection = useCallback((selectionModel) => {
        setSelectedRows(selectionModel);
    }, []);

    const handleDelete = async () => {
        try {
            await Promise.all(selectedRows.map((roleId) => deletePartnerRoleType({ variables: { role_id: roleId } })));
            setSelectedRows([]);
            setOpenDialog(false);
            dispatch(showStatus({ message: 'Partner Role Type(s) deleted successfully', severity: 'success' }));
            refetch();
        } catch (err) {
            dispatch(showStatus({ message: `Error deleting role type(s): ${err.message}`, severity: 'error' }));
        }
    };

    const handleAddClick = () => navigate('/bpm/partnerroletypeview/create');

    const rows = data?.getAllPartnerRoleTypes?.map((role) => ({
        role_id: role?.role_id || '',
        role_name: role?.role_name || '',
        description: role?.description || '',
    })) || [];

    const columns = [
        { field: 'role_name', headerName: 'Role Name', width: 250 },
        { field: 'description', headerName: 'Description', width: 400 },
    ];

    const tableConfig = {
        rows,
        columns,
        uniqueIdColumn: 'role_id',
        rowCount: rows.length,
        onRowClick: handleRowClick,
        onSelectionModelChange: handleRowSelection,
        style: { cursor: 'pointer' },
    };

    const buttonsConfig = {
        add: { label: 'Add' },
        delete: { label: 'Delete', disabled: selectedRows.length === 0 },
    };

    return (
        <Box padding={2}>
            <PageHeader
                title="Partner Role Types"
                buttonsConfig={buttonsConfig}
                onAdd={handleAddClick}
                onDelete={() => setOpenDialog(true)}
                showStatus={true}
            />
            {error && <Typography color="error">Error: {error.message}</Typography>}

            <Box position="relative">
                {loading && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                        bgcolor="rgba(255, 255, 255, 0.7)"
                        zIndex={999}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <ListDataTable config={tableConfig} />
            </Box>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>Are you sure you want to delete the selected role type(s)?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleDelete} color="secondary">Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default PartnerRoleTypesList;

import { gql } from '@apollo/client';
import { valueSetValueFields } from './appValueSetValuesFragments';

export const valueSetValueQueries = {
  GET_VALUE_SET_BY_VALUE_ID: gql`
    query GetValueSetById($valueId: ID!) {
      getValueSetValue(valueId: $valueId) {
        ...ValueSetValueFields
      }
    }
    ${valueSetValueFields}
  `,
  GET_VALUE_SET_VALUES_BY_VALUESET_ID: gql`
    query GetValueSetValuesByValuesetId($valueset_id: ID!, $orderBy: String, $orderDirection: String) {
      getValueSetValuesByValuesetId(valueset_id: $valueset_id, orderBy: $orderBy, orderDirection: $orderDirection) {
        ...ValueSetValueFields
      }
    }
    ${valueSetValueFields}
  `,
  GET_VALUE_SET_VALUES: gql`
    query GetValueSetValues($orderBy: String, $orderDirection: String) {
      getValueSetValues(orderBy: $orderBy, orderDirection: $orderDirection) {
        ...ValueSetValueFields
      }
    }
    ${valueSetValueFields}
  `,
  GET_VALUE_SET_VALUE_BY_CODE: gql`
    query GetValueSetValueByCode($valueset_code: String!, $orderBy: String, $orderDirection: String) {
      getValueSetValueByCode(valueset_code: $valueset_code, orderBy: $orderBy, orderDirection: $orderDirection) {
        ...ValueSetValueFields
      }
    }
    ${valueSetValueFields}
  `,
};
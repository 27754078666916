import { gql } from '@apollo/client';

const partnerFragments = {
    allPartnerFields: gql`
        fragment PartnerFields on Partner {
            partner_id
            branch_id
            partner_code
            partner_name
            status
            primary_contact {
                contact_id
                contact_name
                contact_role
                email
                phone_number
                is_primary_contact
            }
            roles {
                partner_role_id
                role {
                    role_id
                    role_name
                    description
                }
                start_date
                end_date
            }
            addresses {
                address_id
                address_type
                street_address
                city
                state
                postal_code
                country
                linked_contact {
                    contact_id
                    contact_name
                }
                is_primary
            }
            contacts {
                contact_id
                contact_name
                contact_role
                email
                phone_number
                is_primary_contact
            }
            relationships {
                relationship_id
                related_partner_id
                relationship_type
                hierarchy_level
                is_active
            }
            accounts {
                account_id
                role {
                    role_id
                    role_name
                }
                account_type
                currency
                balance
                credit_limit
                payment_terms
            }
        }
    `
};

export default partnerFragments;

import { gql } from '@apollo/client';
import partnerFragments from './bpmPartnerFragments';

const partnerQueries = {
    GET_PARTNER_BY_ID: gql`
        query getPartnerById($partner_id: Int!) {
            getPartnerById(partner_id: $partner_id) {
                ...PartnerFields
            }
        }
        ${partnerFragments.allPartnerFields}
    `,

    GET_PARTNER_BY_CODE: gql`
        query getPartnerByCode($partner_code: String!) {
            getPartnerByCode(partner_code: $partner_code) {
                ...PartnerFields
            }
        }
        ${partnerFragments.allPartnerFields}
    `,

    GET_PARTNER_BY_NAME: gql`
        query getPartnerByName($partner_name: String!, $orderBy: String, $orderDirection: String) {
            getPartnerByName(partner_name: $partner_name, orderBy: $orderBy, orderDirection: $orderDirection) {
                ...PartnerFields
            }
        }
        ${partnerFragments.allPartnerFields}
    `,

    GET_ALL_PARTNERS: gql`
        query getAllPartners(
            $page: Int
            $pageSize: Int
            $filters: [SearchFilterInput]
            $orderBy: String
            $orderDirection: String
        ) {
            getAllPartners(
                page: $page
                pageSize: $pageSize
                filters: $filters
                orderBy: $orderBy
                orderDirection: $orderDirection
            ) {
                partners {
                    ...PartnerFields
                }
                totalCount
            }
        }
        ${partnerFragments.allPartnerFields}
    `,
};

export default partnerQueries;

import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Box,
    Typography,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authorQueries from '../graphql/pbmAuthorQueries';
import authorMutations from '../graphql/pbmAuthorMutations';
import ListDataTable from '../../../../components/fwk/dataGrid/ListDataTable';
import FloatingSearch from '../../../../components/fwk/FloatingSearch/FloatingSearch';
import ActionButtonGroup from '../../../../components/fwk/ActionButtonGroup/ActionButtonGroup';
import { showStatus } from '../../../../redux/slices/statusSlice';
import debounce from 'lodash.debounce';

function AuthorList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // States for pagination, search, and selection
    const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
    const [sortModel, setSortModel] = useState([{ field: 'author_id', sort: 'asc' }]);
    const [searchFilters, setSearchFilters] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const queryVariables = useMemo(() => ({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
        filters: searchFilters,
    }), [pagination, searchFilters]);

    const { loading, error, data, refetch } = useQuery(authorQueries.GET_ALL_AUTHORS, {
        variables: queryVariables,
        fetchPolicy: 'network-only',
    });

    const [deleteAuthor] = useMutation(authorMutations.DELETE_AUTHOR);

    // Debounced search handler
    const debouncedSearch = useMemo(() =>
        debounce((filters) => {
            setSearchFilters(filters);
            setPagination((prev) => ({ ...prev, page: 0 })); // Reset to first page
        }, 300), []);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel(); // Cleanup on unmount
        };
    }, [debouncedSearch]);

    const handleSearch = useCallback((filters) => {
        debouncedSearch(filters);
    }, [debouncedSearch]);

    const handlePaginationChange = useCallback((model) => {
        setPagination(model);
    }, []);

    const handleSortChange = useCallback((newSortModel) => {
        setSortModel(newSortModel);
    }, []);

    const handleRowSelection = useCallback((selectionModel) => {
        setSelectedRows(selectionModel);
    }, []);

    const handleRowClick = useCallback((params) => {
        navigate(`/pbm/author/${params.row.author_id}`);
    }, [navigate]);

    const handleAdd = useCallback(() => {
        navigate('/pbm/author/create');
    }, [navigate]);

    const handleDelete = async () => {
        try {
            await Promise.all(selectedRows.map((id) =>
                deleteAuthor({ variables: { author_id: id } })
            ));
            setSelectedRows([]);
            setOpenDialog(false);
            dispatch(showStatus({ message: 'Author(s) deleted successfully', severity: 'success' }));
            refetch();
        } catch (err) {
            dispatch(showStatus({ message: `Error deleting author(s): ${err.message}`, severity: 'error' }));
        }
    };

    const rows = data?.getAllAuthors?.authors || [];
    const totalCount = data?.getAllAuthors?.totalCount || 0;

    const columns = [
        { field: 'author_name', headerName: 'Author Name', width: 200 },
        { field: 'biography', headerName: 'Biography', width: 300 },
        { field: 'nationality', headerName: 'Nationality', width: 150 },
    ];

    const tableConfig = {
        rows,
        columns,
        uniqueIdColumn: 'author_id',
        rowCount: totalCount,
        paginationModel: pagination,
        paginationMode: 'server',
        onPaginationModelChange: handlePaginationChange,
        onRowSelectionModelChange: handleRowSelection,
        onSortModelChange: handleSortChange,
        style: { cursor: 'pointer' },
        onRowClick: handleRowClick,
        onSelectionModelChange: handleRowSelection,
    };

    const buttonsConfig = {
        add: { label: 'Add' },
        delete: { label: 'Delete', disabled: selectedRows.length === 0 },
    };

    return (
        <Box padding={2}>
            <Grid container justifyContent="space-between" alignItems="center" marginBottom={2}>
                <Grid item>
                    <h3>Authors</h3>
                </Grid>
                <Grid item>
                    <ActionButtonGroup
                        buttonsConfig={buttonsConfig}
                        onAdd={handleAdd}
                        onDelete={() => setOpenDialog(true)}
                    />
                </Grid>
            </Grid>
            {error && (
                <Typography color="error" style={{ marginBottom: '16px' }}>
                    Error: {error.message}
                </Typography>
            )}
            <FloatingSearch columns={columns} onSearch={handleSearch} />
            <Box position="relative">
                {loading && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                        bgcolor="rgba(255, 255, 255, 0.7)"
                        zIndex={999}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <ListDataTable config={tableConfig} />
            </Box>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete the selected authors?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AuthorList;

import { gql } from '@apollo/client';

export const userFragments = {
  userFields: gql`
    fragment UserFields on User {
      user_id
      username
      email
      first_name
      last_name
      date_of_birth
      gender
      profile_picture
      last_login
      is_active
      country
      city
      postal_code
      street_address
      phone_number
      is_verified
      verification_token
      timezone
      language
      employee_id
      roles {
        user_role_id
        role_id
        active
        role_name
        role_code
      }
      organizations {
        organization_id
        organization_name
        address
        default
      }
      branches {
        branch_id
        branch_name
        organization_id
        address
      }
      is_admin
    }
  `,
  userListFields: gql`
    fragment UserListFields on User {
      user_id
      profile_picture
      username
      first_name
      last_name
      email
      gender
      is_active
      country
      city
      phone_number
      is_verified
      organizations {
        organization_id
        organization_name
      }
      branches {
        branch_id
        branch_name
      }
      editionCenters {
        edition_center_id
        edition_center_name
      }
    }
  `,
};

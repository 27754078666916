import { gql } from '@apollo/client';

const fragments = {
    componentFields: gql`
        fragment ComponentFields on Component {
            component_id
            component_name
            component_type
            position_x
            position_y
            width
            height
        }
    `
};

export const GET_MODULE_DASHBOARD = gql`
    query GetModuleDashboard($module_code: String!) {
        getModuleDashboard(module_code: $module_code) {
            dashboard_id
            dashboard_name
            components {
                ...ComponentFields
            }
        }
    }
    ${fragments.componentFields}
`;

export const GET_COMPONENT_DATA = gql`
    query GetComponentData($component_id: Int!) {
        getComponentData(component_id: $component_id) {
            data
        }
    }
`;

export default { GET_MODULE_DASHBOARD, GET_COMPONENT_DATA };

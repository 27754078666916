import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

function ConfirmModal({ open, onClose, onConfirm, message, children }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <p>{message}</p>
        {children} {/* Render custom content inside the modal */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          Yes
        </Button>
        <Button onClick={onClose} color="secondary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
